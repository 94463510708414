import React, { useEffect, useState } from "react";
import { fetchGet } from "../utils/helpers";
import { Urls } from "../utils/AppConfig";
import { useNavigate } from "react-router-dom";

const Plans = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const loadPlans = async () => {
      let result = await fetchGet(Urls.GetPlans);
      if (result.error) {
        return;
      }
      setPlans(result.data);
    };
    loadPlans();
  }, []);

  return (
    <>
      <div className="container">
        <h5>Plans</h5>
        {plans.map((plan) => {
          return (
            <div
              className="flex justify-between pt-4 pb-4 pl-8 pr-16 m-2 border-2 rounded-md hover:bg-[#333] cursor-pointer"
              key={plan.id}
              style={{ border: "1px solid #00ff99" }}
              onClick={()=>{
                navigate(`/plan?id=${plan['report_id']}`)
              }}
            >
              <div className="flex flex-col items-start">
                <div>
                  <span className="text-[20px] text-[#00ff99]">{plan["address"]}</span> ({plan['no_of_floors']} Floor(s) {plan['house_type']})
                  <span className="ml-4 text-[18px]">
                    {
                        plan['is_report_success']?<span className="text-[#00ff00]">&#128442;</span>:<></>
                    }
                    {
                        plan['is_report_error']?<span className="text-[#ff0000]">&#128442;</span>:<></>
                    }
                    {
                        !plan['is_report_success'] && !plan['is_report_error']?<span className="text-[#ffaa00]">&#128442;</span>:<></>
                    }
                    {
                        plan['is_new']?<span className="text-[#FFD700]">&#10039;</span>:<></>
                    }
                    
                  </span>
                </div>
                <div>
                  <i>
                    <span className="text-[#ddd] text-[12px]">
                      {new Date(plan["created_on"]).toLocaleString()}
                    </span>
                  </i>
                </div>
              </div>
              <div className="items-start text-[20px]">
                {/* &#x1F7E0; */}
                {
                    plan['status']==="FAILED"||plan['status']==="ERROR"?<span className="text-[#ff0000]">&#9888;</span>:plan['status']==="SUCCESS"?<span className="text-[#00ff99]">&#10003;</span>:<span className="text-[#FFC300]">&#9202;</span>
                }
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Plans;
