import * as React from "react";
import { connect, useDispatch } from "react-redux";
import OverviewDetails from "./OverviewDetails";
import SalesHistory from "./SalesHistory";
import TitleValuation from "./TitleValuation";
import { toggleManualOrderDialog, togglePaywallDialog } from "../../actions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RightComponent from "./RightComponent";
import { Cross2Icon } from "@radix-ui/react-icons";

const mapStateToProps = (state) => {
  return {
    data: state.DataReducer,
  };
};

const ParcelDetailPanel = (props) => {
  const dispatch = useDispatch();
  const subscriptionData = props.data.currentSubscription;

  if (!props.data.raw) {
    return <></>;
  }

  // const [salesHistory, setSalesHistory] = React.useState([]);
  const salesHistory = props.data.raw.salesHistory;
  // const [rateAssessment, setRateAssessment] = React.useState(null);
  const rateAssessment = props.data.raw.rateAssessment;
  // const [parcelOverview, setLocalParcelOverview] = React.useState({});
  const parcelOverview = props.data.raw.parcelOverview;

  const networkCapacity = props.data.raw.networkCapacity;

  if (!props.data.currentParcelId && props.panelType === "mobile") {
    props.minimisePanel();
    return <></>;
  }

  const openAutoReportForm = () => {
    dispatch(
      toggleManualOrderDialog(true, {
        address: props.data.fullAddress,
        addressId: props.data.currentAddressId,
      })
    );
  };

  return (
    <div
      id="parcel_details_panel"
      className={props.panelType === "mobile" ? "overflow-y-auto h-full" : ""}
      style={{
        padding: 0,
        width: "100%",
        background: "transparent",
        fontFamily: "Verdana, sans-serif",
        height: props.panelType === "mobile" ? "100%" : "calc(100vh - 66px)",
        overflowX: 'hidden'
      }}
    >
      {props.data.currentParcelId ? (
        <>
          <div>
            <div
              style={{
                position: props.panelType === "mobile" ? "fixed" : "relative",
                width: "100%",
                padding: 8,
                background: 'rgb(47 47 47)'
              }}
            >
              <h6 className="header-3-small">
                {props.data.fullAddress ? props.data.fullAddress : ""}
                <div style={{ float: "right" }}>
                  {props.panelType === "mobile" ? (
                    <button onClick={props.minimisePanel}>
                      <Cross2Icon color="#00ff99 " className="w-6 h-6 " />
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </h6>
            </div>

            <div
              style={
                props.panelType === "mobile"
                  ? {paddingTop: 48}
                  : { overflowY: "auto", height: "calc(100vh)" }
              }
            >
              <OverviewDetails subscription={subscriptionData} data={parcelOverview} />
                {
                    networkCapacity?<div style={{marginLeft: 18, color: networkCapacity['service']==='Wastewater'?'#FF5733':networkCapacity['service']==='Water and Wastewater'?'#7c7c7c':networkCapacity['service']==='Water'?'#12abe0':'#00FF99', fontSize: 15}}>
                        <span style={{color: 'yellow'}}>&#9888; </span> Network constraint: {networkCapacity['constraint']}, {networkCapacity['area']} area
                    </div>:''
                }
              <TitleValuation data={rateAssessment} />
              <SalesHistory data={salesHistory} />

              {Boolean(props.data.user) ? (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  padding: 8,
                  borderRadius: 3,
                  background: "transparent",
                  bottom: 0,
                }}
              >
                <Row>
                  <Col xs={12} md={12} item>
                    <button
                      className="btn btn-small"
                      style={{
                        width: "100%",
                        color: "#00FF99",
                        backgroundColor: "#333",
                        borderColor: "#00FF99",
                      }}
                      onClick={openAutoReportForm}
                    >
                      Unlock the Potential
                    </button>
                  </Col><Col xs={12} md={12} item>
                    <button
                      className="btn btn-small"
                      style={{
                        width: "100%",
                        color: "#00FF99",
                        backgroundColor: "#333",
                        borderColor: "#00FF99",
                      }}
                      onClick={()=>{
                        if(subscriptionData){
                          window.open(
                            "https://projectx.nz/design?q="+props.data.currentParcelId,
                            "_blank"
                          )
                        }else{
                          //TODO
                          dispatch(togglePaywallDialog(true, false, true));
                        }
                      }}
                    >
                      {
                        subscriptionData?<>Open PlotX Design Platform</>:<span className={'cursor-pointer text-[#ff0000]'}>&#128274; Unlock PlotX Design Platform</span>
                      }
                      
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} item></Col>
                </Row>
              </div>
            ) : (
              ""
            )}
              <RightComponent subscription={subscriptionData} zoneData={props.data.zoneData} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ position: "relative", width: "100%", padding: 32 }}>
            <h6 className="header-3-small">
              Please, select a parcel to get details and insights.
            </h6>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ParcelDetailPanel);
