import {
  MapContainer,
  useMap,
  GeoJSON,
  useMapEvent,
  ZoomControl,
} from "react-leaflet";
import { connect, useDispatch } from "react-redux";
import L from "leaflet";
import React from "react";
import {
  addLayer,
  addSearchLayer,
  setParcelOverview,
  setZoneData,
  toggleLoader,
  updateCurIds,
  updateRaw,
} from "../actions";
import { generateId, toSlug } from "../utils/helpers";
import { Urls } from "../utils/AppConfig";
import LayerList from "./LayerList";
import { useState } from "react";
import { LayersIcon } from "@radix-ui/react-icons";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../shadcn/components/ui/drawer";
import { Button } from "../shadcn/components/ui/button";

import ParcelDetailPanel from "./RightPanel/ParcelDetailPanel";
import AddressSearch from "./AddressSearch";

const mapStateToProps = (state) => {
  return {
    map: state.MapReducer,
    data: state.DataReducer,
  };
};

const MapLayers = (props) => {
  const map = useMap();

  let currentLayers = {};
  let currentLayerIds = Object.keys(map._layers).map((k) => {
    currentLayers[map._layers[k]["options"]["id"]] = map._layers[k];
    return map._layers[k]["options"]["id"];
  });
  let tLayers = Object.keys(props.map.layers).map(
    (layerId) => props.map.layers[layerId]
  );
  tLayers = tLayers.sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  });
  for (let i = 0; i < tLayers.length; i++) {
    if (currentLayerIds.indexOf(tLayers[i].id) === -1 && tLayers[i].active) {
      let currentLayer = tLayers[i];
      let layer = null;

      if(!props.subscription && currentLayer.needSubscription){
        continue;
      }

      if (currentLayer.type === "WMS") {
        layer = L.tileLayer.wms(currentLayer.url, {
          layers: currentLayer.id,
          format: "image/png",
          transparent: true,
          maxZoom: 24,
        });
      }
      if (currentLayer.type === "WMTS") {
        layer = L.tileLayer(
          "https://projectx.nz/geoserver/gwc/service/wmts?layer=projectx%3Anz_contour_lines&style=&tilematrixset=NZ3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=NZ3857%3A{z}&TileCol={x}&TileRow={y}",
          {
            maxNativeZoom: 19,
          }
        );
      }
      if (currentLayer.type === "VECTOR" || currentLayer.type === "VECTOR_GJ") {
        console.log("Adding:", currentLayer.id);
        layer = L.geoJSON(currentLayer.data);
      }
      if (currentLayer.type === "TILE") {
        layer = L.tileLayer(currentLayer.url, {
          maxZoom: 24,
        });
      }
      if (layer) {
        layer.options["id"] = currentLayer.id;
        layer.options["sortOrder"] = currentLayer.sortOrder;
        if (!(currentLayer.id in currentLayers)) map.addLayer(layer);
      }
    }
    if (!tLayers[i].active && currentLayerIds.indexOf(tLayers[i].id) > -1) {
      map.removeLayer(currentLayers[tLayers[i].id]);
    }
  }

  //loop through map layers & remove
  currentLayers = {};
  let layersToBeVisible = tLayers.filter((e) => e.active).map((e) => e.id);
  let layersInMap = Object.keys(map._layers)
    .map((k) => {
      currentLayers[map._layers[k]["options"]["id"]] = map._layers[k];
      return map._layers[k]["options"]["id"];
    })
    .filter((e) => Boolean(e));
  // console.log(layersToBeVisible, layersInMap);
  let layersToRemove = layersInMap
    .filter((e) => layersToBeVisible.indexOf(e) === -1)
    .map((e) => {
      let ltr = currentLayers[e];
      map.removeLayer(ltr);
    });
  // console.log("Layer to be removed", layersToRemove);
  // Object.keys(currentLayers).map(key => {
  //     let layer = currentLayers[key];
  //     if ((tLayers.map(t => t.id).indexOf(key) === -1) || (!tLayers[tLayers.map(t => t.id).indexOf(key)].active)) {
  //         console.log("Removing layer: ", key);
  //         if (map.hasLayer(layer)) {
  //             map.removeLayer(layer);
  //         }
  //     }
  // });

  currentLayers = Object.keys(map._layers)
    .map((k) => {
      return map._layers[k]; //['options']['id'];
    })
    .filter((e) => e["options"]["sortOrder"] !== undefined)
    .sort((a, b) => {
      return a["options"].sortOrder - b["options"].sortOrder;
    });
  currentLayers.forEach((_layer) => {
    // if (_layer['options']['sortOrder'] === undefined) return;
    // console.log(_layer['options'].sortOrder)
    if (_layer.bringToFront) {
      _layer.bringToFront();
      // console.log("YES", _layer)
    }
    // else
    //     console.log("NO", _layer)
  });

  return <></>;
};

function ClickEvent(props) {
  const dispatch = useDispatch();
  useMapEvent("click", (e) => {
    console.log([e.latlng.lng, e.latlng.lat]);
    let coordinates = [e.latlng.lng, e.latlng.lat];
    dispatch(toggleLoader(true));
    let token = window.localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    fetch(`${Urls.ParcelByLocation}?coordinates=${coordinates.join(",")}`, {
      headers: headers,
    })
      .then((r) => r.json())
      .then((parcelData) => {
        let urlSlug =
          parcelData.data["properties"]["address_id"] +
          "-" +
          toSlug(parcelData.data["properties"]["full_address"]);
        console.log(urlSlug);

        window.history.replaceState(
          null,
          "Plot Potential - What Can I Do With My Plot?",
          `/map?q=${urlSlug}`
        );

        dispatch(toggleLoader(false));
        let searchLayerId = `address_search_${generateId(3)}`;
        dispatch(addSearchLayer(searchLayerId));
        dispatch(
          addLayer({
            type: "VECTOR_GJ",
            id: searchLayerId,
            active: true,
            data: parcelData.data.geometry,
            name: "Address Search",
            sortOrder: -2,
            showLegend: false,
            showInLayerList: false,
          })
        );
        dispatch(
          updateCurIds({
            parcelId: parcelData.data.properties.parcelId,
            addressId: parcelData.data.properties.address_id,
            titleNo: parcelData.data.properties.titleNo,
            addressGeometry: parcelData.data.addressGeometry,
            fullAddress: parcelData.data.properties["full_address"],
          })
        );
        dispatch(
          updateRaw({
            address: parcelData.data.raw.address,
            parcel: parcelData.data.raw.parcel,
            gisDetails: parcelData.data.raw.parcelOverview.raw.gisDetails,
            pc78: parcelData.data.raw.parcelOverview.raw.pc78,
            title: parcelData.data.raw.parcelOverview.raw.title,
            salesHistory: parcelData.data.raw.salesHistory,
            rateAssessment: parcelData.data.raw.rateAssessment,
            parcelOverview: parcelData.data.raw.parcelOverview,
            networkCapacity: parcelData.data.raw.networkCapacity,
          })
        );
        delete parcelData.data.raw.parcelOverview["raw"];
        dispatch(setParcelOverview(parcelData.data.raw.parcelOverview));
        dispatch(setZoneData(parcelData.data.raw.zoneRegulations));
        // dispatch(toggleLoader(false));

        // fetchPost(
        //   Urls.AddSearchHistory,
        //   {
        //     parcelId: parcelData.data.properties.parcelId,
        //     address: parcelData.data.properties["full_address"],
        //   },
        //   token
        // ).then((response) => {
        //   if (response.error) {
        //     return;
        //   }
        //   console.log(response);
        // });
        props.setOpened(true);
        //CALCULATE PLAN

        // fetch(`${Urls.GeneratePlan}`, {
        //     body: JSON.stringify({
        //         geometry: parcelData.data.geometry
        //     }),
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     method: 'POST'
        // })
        //     .then(r => r.json())
        //     .then(planResult => {
        //         console.log(planResult)
        //     })
      })
      .catch((err) => {
        dispatch(toggleLoader(false));
      });
  });
  return null;
}

const Map = (props) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <div className="overflow-hidden h-[calc(100vh-72px)]">
        <div class="md:grid grid-cols-12 ">
          <div className="hidden lg:block  xl:col-span-2 lg:col-span-3 h-[calc(100vh-72px)]   border-2 border-[#00ff99]">
            <LayerList />
          </div>
          <div
            className={` bottom-0 h-2/4  w-full bg-black z-20 absolute lg:hidden ${
              opened ? "h-2/4" : "hidden"
            }`}
            style={{ opacity: 0.9 }}
          >
            <ParcelDetailPanel
              panelType={"mobile"}
              minimisePanel={() => {
                setOpened(false);
              }}
            />
          </div>

          <div
            className={`xl:col-span-7 lg:col-span-5':'xl:col-span-10 lg:col-span-9 md:col-span-12 col-span-12 relative`}
          >
            <MapContainer
              className="h-[calc(100vh-72px)] z-10"
              minZoom={8}
              maxZoom={24}
              style={{ width: "100%" }}
              center={props.map.mapView.center}
              zoom={props.map.mapView.zoom}
              zoomControl={false}
              key={`${props.map.mapView.center[0]}${props.map.mapView.center[1]}`}
            >
              <ZoomControl position="topright" />
              <ClickEvent setOpened={setOpened} />
              <MapLayers subscription={props.data.currentSubscription} map={props.map} />
              {props.map.searchLayer ? (
                <GeoJSON data={props.map.searchLayer.data} />
              ) : (
                ""
              )}
            </MapContainer>
            <div className="lg:hidden block mt-[10px] ml-[10px]" style={{
              zIndex: 1000,
              position: "absolute",
              top: 10,
              width: '100%'
            }}>
              <LayerControl />
              
            </div>
          </div>
          <div
            className={`lg:block overflow-y-auto xl:col-span-3 lg:col-span-4 h-[calc(100vh-72px)] border-2 border-[#00ff99]}`}
          >
            <ParcelDetailPanel
              panelType={"desktop"}
              minimisePanel={() => {
                // setOpened(false);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

function LayerControl() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="lg:hidden inline-block  "
      style={{ 
        display: 'flex',fontFamily: "Verdana, sans-serif" }}
    >
      <div
        class="focus:text-teal-500 hover:text-teal-500  flex flex-col items-center justify-center p-2 bg-black border-2 border-[#00ff99]"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
        style={{
          width: 40,
          height: 40,
          display: isOpen ? "none" : "flex",
        }}
      >
        <div>
          <LayersIcon className="inline-block mb-1 h-6 w-6" color="#00ff99" />
        </div>
      </div>
      <div className="lg:hidden ml-[10px]" style={{background: '#000000DD', width: "calc(100% - 115px)", display: isOpen ? "none" : "flex"}}>
        <AddressSearch isInput={false} height={40} />
      </div>
      <Drawer open={isOpen} onOpenChange={setIsOpen}>
        <DrawerContent>
          <div className="mx-auto w-full max-w-sm ">
            <DrawerHeader>
              <DrawerTitle>Layers List</DrawerTitle>
            </DrawerHeader>
            <LayerList />
            <DrawerFooter>
              <DrawerClose asChild>
                <Button variant="pp">Back To Map</Button>
              </DrawerClose>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default connect(mapStateToProps)(Map);
