/* eslint-disable react-hooks/exhaustive-deps */
import { connect, useDispatch } from "react-redux"
import { toggleAlertDialog, toggleReportLoader, toggleManualOrderDialog, toggleSamplePlansDialog, toggleLoader, toggleProgressLoader } from "../actions"
import { Constants, Theme, Urls } from "../utils/AppConfig"
import { useEffect, useState } from "react"
import { fetchGet, fetchPost, toSlug } from "../utils/helpers";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import AddressInput from "./AddressInput"

const mapStateToProps = (state) => {
    return {
        dialog: state.DialogReducer,
        data: state.DataReducer,
        map: state.MapReducer
    }
}

const style = {
    backgroundColor: '#171717',
    border: '2px solid #000',
    boxShadow: 24,
}


const OrderAutoReport = (props) => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const [fullAddress, setFullAddress] = useState(props.selectedAddress ? props.selectedAddress.name : '');
    const [addressId, setAddressId] = useState(props.selectedAddress ? props.selectedAddress.id : '');
    const [openGarage, setOpenGarage] = useState('garage');
    const [houseTypes, setHouseTypes] = useState(['standalone']);
    const [noOfFloors, setNoOfFloors] = useState(['2']);
    const [selectedAddress, setSelectedAddress] = useState(props.selectedAddress);
    const [formValid, setFormValid] = useState(null);
    const [existingDwelling, setExistingDwelling] = useState('dontkeep');
    const [hasMinorDwelling, setHasMinorDwelling] = useState('hasminordwelling');
    const [isSingleHouse] = useState(['Residential - Large Lot Zone', 'Residential - Single House Zone', 'Residential - Rural and Coastal Settlement Zone',].includes(props.data.parcelOverview['Zoning']));

    const [zoneName] = useState(props.data.parcelOverview['Zoning']);
    const [zoneData] = useState(props.data.zoneData);
    const [existingDwellingArea, setExistingDwellingArea] = useState(0);
    const [existingDwellingNo, setExistingDwellingNo] = useState(0);
    const [canSupportMD, setCanSupportMD] = useState(isSingleHouse);
    const [canKeepExD, setCanKeepExD] = useState(true);
    const [isRegular, setIsRegular] = useState(true);
    const [canSupportExtension, setCanSupportExtension] = useState(false);

    const isMapPage = (window.location.href.indexOf("/map") > -1);

    const checkJobStatus = (jobId, reportId, quota) => {
        const intervalId = setInterval(async () => {
            try {
                let jr = await fetchGet(`${Urls.GetJobStatus}?jobId=${jobId}`);
                if (jr.error) {
                    clearInterval(intervalId);
                    dispatch(toggleProgressLoader(false));
                    dispatch(toggleAlertDialog(true, "Error", "Server error. Please, try again."));
                    return;
                }

                if (jr.data['status'] === 'COMPLETED' || jr.data['status'] === 'ERROR') {
                    clearInterval(intervalId);
                    let r = await fetchGet(`${Urls.GetArchPlanUrls}?reportId=${reportId}`);
                    dispatch(toggleProgressLoader(false));
                    
                    if (r.error) {
                        dispatch(toggleAlertDialog(true, "In development", r.message));
                        dispatch(toggleManualOrderDialog(false));
                        return;
                    }
                    
                    let archResult = r.data.result;
                    dispatch(toggleSamplePlansDialog(true, archResult, quota));
                    dispatch(toggleManualOrderDialog(false));
                }
                if (jr.data['status'] === 'IN_PROGRESS') {
                    dispatch(toggleProgressLoader(true, jr.data["message"], jr.data["progress_percent"]));
                }
            } catch (error) {
                clearInterval(intervalId);
                dispatch(toggleProgressLoader(false));
                dispatch(toggleAlertDialog(true, "Error", "Unexpected error occurred."));
            }
        }, 2000);
    }
    

    const createArchPlans = async () => {

        if(['Residential - Mixed Housing Urban Zone', 'Residential - Terrace Housing and Apartment Building Zone', 'Residential - Mixed Housing Suburban Zone', 'Residential - Large Lot Zone', 'Residential - Single House Zone', 'Residential - Rural and Coastal Settlement Zone',].indexOf(props.data.parcelOverview['Zoning']) === -1){
            dispatch(toggleManualOrderDialog(false))
            dispatch(toggleAlertDialog(true, "In development", `${props.data.parcelOverview['Zoning']} is not supported yet. We are working on including more zones to our system.`))
            return;
        }
        dispatch(toggleProgressLoader(true, "Generating plan...", 0));
        try{
            let r = await fetchGet(`${Urls.GetArchPlans}?garage=${openGarage}&addressId=${addressId}&noOfFloors=${noOfFloors.join(',')}&houseTypes=${houseTypes.join(',')}&keepExDwellings=${existingDwelling}&hasMinorDwelling=${hasMinorDwelling}&isRegular=${isRegular}&addressName=${fullAddress}`)
            // dispatch(toggleProgressLoader(false));
            if (r.error) {
                dispatch(toggleAlertDialog(true, "Error", "Server error. Please, try again."));
                return;
            }
            // dispatch(toggleAlertDialog(true, "Architecture Plan", "Your request is submitted. Please, check the status in the notification menu."));
            checkJobStatus(r.data.jobId, r.data.reportId, r.data.quota);
        }catch(e){
            console.log(e);
            window.alert("Error")
            dispatch(toggleProgressLoader(false));
        }
    }

    useEffect(()=>{
        if(zoneData){
            setIsRegular(zoneData['isRegular']);
            setExistingDwellingArea(zoneData.buildings.reduce((a, b)=>a+b['building_area'], 0));
            setExistingDwellingNo(zoneData.buildings.length);
            if(isSingleHouse){
                console.log(existingDwelling)
                if(
                    existingDwelling === 'keep' && (                    zoneData.buildings.length >= 2
                    || ((zoneData['buildingCoverageArea']-existingDwellingArea) < 35 && (zoneData['buildingCoverageArea']-existingDwellingArea) > 0))
                ){
                    setCanSupportMD(false);
                }else{
                    setCanSupportMD(true);
                }
                if(zoneData.buildings.length >=2 && ((zoneData['buildingCoverageArea']-existingDwellingArea) < 35 && (zoneData['buildingCoverageArea']-existingDwellingArea) > 0)){
                    setCanSupportExtension(true);
                }
                if(zoneData['buildingCoverageArea']-existingDwellingArea <= 0){
                    setCanKeepExD(false)
                }else{
                    setCanKeepExD(true)
                }
            }else{
                setCanSupportMD(false);
                setCanSupportExtension(false);
            }
        }
    }, [zoneData, existingDwelling])

    useEffect(() => {
        let flag = true;
        if (houseTypes.length === 0) {
            flag = false;
        }
        if (noOfFloors.length === 0) {
            flag = false;
        }
        if (!Boolean(selectedAddress)) {
            flag = false;
        }
        setFormValid(flag);
        if (Boolean(selectedAddress)) {
            setAddressId(selectedAddress['id']);
            setFullAddress(selectedAddress['name']);
        }
        // Based on selected parcel show options
        // Zone (SH)
        // dispatch(toggleLoader(true));
        
    }, [selectedAddress, houseTypes])

    return <>
        <Modal
            show={Boolean(props.dialog.manualOrderDialog.flag)}
            onHide={() => { dispatch(toggleManualOrderDialog(false)) }}
            centered
            size="lg"
            style={{
                backgroundColor: 'transparent'
            }}
        >
            <div sx={style}>
                <div id="modal-modal-description" sx={{ mt: 2 }} style={{
                    background: 'rgba(0, 0, 0, 0.6)',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.6)',
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(5px)',
                    color: '#00FF99'
                }}>
                    <div style={{ padding: 24, border: `2px solid ${Theme.PrimaryLogo}`, borderRadius: 2 }}>
                        <p className="header-2-small" style={{ textAlign: 'center' }}>
                            Auto Report
                        </p>
                        <hr style={{ color: Theme.PrimaryLogo }} />
                        <p className="content-text-details-2" style={{ fontSize: '15px !important' }}>
                            Auto-generated Report crafted by our system, incorporating data from the Unitary Plans of various districts, development restrictions, and cutting-edge GIS technology.
                        </p>

                        {/* <p className="content-text-details-2" style={{ fontSize: '15px !important' }}>
                            {JSON.stringify(props.dialog.manualOrderDialog.preprocess)}
                        </p> */}


                        <label className="input-label">
                            Plot Address
                        </label>
                        <Row>
                            <Col xs={11}>
                                <AddressInput onClick={(data) => {
                                    console.log(data);
                                    setSelectedAddress(data);
                                }} defaultValue={fullAddress} />
                            </Col>
                            <Col xs={1}>
                                <div style={{ textAlign: 'right' }}>
                                    <button disabled={!Boolean(!isMapPage && selectedAddress && selectedAddress.id)} className="btn btn-small" onClick={() => {
                                        let urlSlug = selectedAddress.id + '-' + toSlug(selectedAddress.name);
                                        // window.history.replaceState(null, "Plot Potential - What Can I Do With My Plot?", `/map?q=${urlSlug}`);
                                        // navigate(`/map?q=${urlSlug}`)
                                        window.location.href = `/map?q=${urlSlug}`
                                    }}>
                                        {/* <FontAwesomeIcon icon="fa-solid fa-map" /> */}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        {
                            isSingleHouse ? (
                                <div style={{ display: 'none' }}>
                                    <label className="input-label">
                                        Garage type
                                    </label>
                                    <Form.Select className="select-input" value="garage" disabled>
                                        <option value="garage">With Garage</option>
                                    </Form.Select>
                                </div>
                            ) : (
                                <>
                                    <label className="input-label">
                                        Garage type
                                    </label>
                                    <Form.Select
                                        className="select-input"
                                        value={openGarage === 'open_parking' ? 'open_parking' : 'garage'}
                                        onChange={e => setOpenGarage(e.target.value)}
                                    >
                                        <option value="open_parking">Open parking</option>
                                        <option value="garage">With Garage</option>
                                    </Form.Select>
                                </>
                            )
                        }
                        <br />

                        {
                            isSingleHouse?<>
                                    
                                &#9432; For {zoneName}, only standalone house type is supported.
                            </>:<></>
                        }


                        <label className="input-label">
                            House type
                        </label>
                        <br />
                        <Form.Select className="select-input" value={houseTypes} onChange={e => setHouseTypes([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                            <option value="standalone">Standalone</option>
                            {!isSingleHouse ? (<><option value="zerolot">Zerolot</option>
                                <option value="duplex">Duplex</option>
                                <option value="terrace">Terrace</option></>) : ''}
                        </Form.Select>

                        <br />
                        <label className="input-label">
                            No. of Floors
                        </label>
                        <br />
                        <Form.Select
                            className="select-input"
                            value={noOfFloors}
                            onChange={e => setNoOfFloors([].slice.call(e.target.selectedOptions).map(item => item.value))}
                        >
                            <option value="1">One storey</option>
                            <option value="2">Two storey</option>
                            {!isSingleHouse && <option value="3">Three storey</option>}
                        </Form.Select>

                        {
                            zoneData && isSingleHouse?<>
                                Existing building coverage: {existingDwellingArea.toFixed(1)} sq.m.
                            </>:''
                        }
                        {
                            !canKeepExD?<>
                                No modifications possible without demolishing exisitng dwellings
                            </>:''
                        }

                        <br />
                        <label className="input-label">
                            Existing Dwelling
                        </label>
                        <br />
                        <Form.Select className="select-input" value={existingDwelling} onChange={e => setExistingDwelling(e.target.value)}>
                            {
                                canKeepExD?<option value="keep">Keep</option>:''
                            }
                            <option value="dontkeep">Demolish</option>
                        </Form.Select>
                        {
                            isSingleHouse && !canSupportMD?<>Minor dwelling cannot be placed without demolishing existing dwelling.</>:''
                        }
                        {
                            isSingleHouse ? (<>
                                <br />
                                <label className="input-label">
                                    Has Minor Dwelling?
                                </label>
                                <br />
                                <Form.Select className="select-input" value={hasMinorDwelling} onChange={e => setHasMinorDwelling(e.target.value)}>
                                    {
                                        canSupportMD?<option value="hasminordwelling">Yes</option>:''
                                    }
                                    <option value="nominordwelling">No</option>
                                </Form.Select>
                            </>
                            ) : ''
                        }

                        <br />
                        <br />

                        <button disabled={!formValid} className="btn-inv" onClick={() => { createArchPlans() }}>Generate Architectural Plan</button>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default connect(mapStateToProps)(OrderAutoReport);