import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardTitle,
  CardHeader,
} from "../shadcn/components/ui/card";
import { Separator } from "../shadcn/components/ui/separator";
import { Button } from "../shadcn/components/ui/button";
import { fetchGet } from "../utils/helpers";
import { Urls } from "../utils/AppConfig";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { toggleAlertDialog, togglePaywallDialog, toggleReportLoader } from "../actions";


const mapStateToProps = (state) => {
    return {
        data: state.DataReducer
    }
}

const PlanDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscriptionData = props.data.currentSubscription;
  const [searchParams, setSearchParams] = useSearchParams();
  const [planData, setPlanData] = useState(null);
  let reportId = searchParams.get("id");

  useEffect(() => {
    const loadPlanDetails = async (reportId) => {
      let result = await fetchGet(
        Urls.GetPlanDetails + `?reportId=${reportId}`
      );
      if (result.error) {
        return;
      }
      setPlanData(result.data);
    };
    loadPlanDetails(reportId);
  }, []);

  const generateReport = async (planId) => {
    if(!subscriptionData){
        dispatch(togglePaywallDialog(true, false, true));
        return;
    }
    let token = window.localStorage.getItem('token');
    dispatch(toggleReportLoader(true));
    let reportOrder = await fetchGet(`${Urls.CreateReportOrder}?planId=${planId}&token=${token}`);
    dispatch(toggleReportLoader(false));
    if (reportOrder.error) {
        console.log(reportOrder);
        return dispatch(toggleAlertDialog(true, "Error", "Server error. Please, try again later."));
    }
    dispatch(toggleAlertDialog(true, "Report", "Your request is submitted. Please, check the report status in the notification menu."));
  }

  if (!planData) {
    return <>...</>;
  }

  return (
    <>
      <div className="container">
        <div>
          <div className="flex items-center justify-center w-full py-3 px-2">
            <div className="md:p-5 p-1 w-full xl:w-2/3 mt-5">
              <Card className="w-full  p-0 text-white border-none bg-[#111111] border-2">
                <CardHeader>
                  <div className="flex items-center justify-center gap-4">
                    <div className="font-medium"></div>
                    <div>
                      <CardTitle className="text-[#00ff99]">
                        {" "}
                        Plan Details
                      </CardTitle>
                    </div>
                  </div>
                </CardHeader>

                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    <div
                      onClick={() => {}}
                      style={{
                        cursor: "pointer",
                        display: "inline-block",
                        background: "#333",
                        padding: 20,
                        borderRadius: 10,
                        boxShadow: "2px 2px 2px 2px black",
                        marginBottom: 10,
                      }}
                    >
                      <img
                        alt=""
                        src={planData["imageUrl"]}
                        style={{
                          maxWidth: 300,
                          maxHeight: 300,
                          objectFit: "contain",
                          filter: subscriptionData?"":"blur(5px)"
                        }}
                        onClick={()=>{
                            if(!subscriptionData){
                                dispatch(togglePaywallDialog(true, false, true));
                                return;
                            }
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 items-center justify-center">
                    <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                      <div className="text-[#00ff99] font-extrabold ">
                        Report ID
                      </div>
                      <div className="font-medium">{reportId}</div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                      <div className="text-[#00ff99] font-extrabold ">
                        Status
                      </div>
                      <div className="font-medium">
                        {planData["status"] === "FAILED" ||
                        planData["status"] === "ERROR" ? (
                          <span className="text-[#ff0000]">&#9888; Failed</span>
                        ) : planData["status"] === "SUCCESS" ? (
                          <span className="text-[#00ff99]">
                            &#10003; Generated
                          </span>
                        ) : (
                          <span className="text-[#FFC300]">
                            &#9202; In Progress
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                      <div className="text-[#00ff99] font-extrabold ">
                        Parcel ID{" "}
                      </div>
                      <div className="font-medium">{planData["parcel_id"]}</div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                      <div className="text-[#00ff99] font-extrabold ">
                        Address
                      </div>
                      <div className="font-medium">{planData["address"]}</div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                      <div className="text-[#00ff99] font-extrabold ">
                        Generated on{" "}
                      </div>
                      <div className="font-medium">
                        {new Date(planData["created_on"]).toLocaleString()}
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                      <div className="text-[#00ff99] font-extrabold ">
                        Summary{" "}
                      </div>
                      <div className="font-medium">
                        <>
                          {planData["no_of_floors"]} Floor(s){" "}
                          {planData["house_type"]}
                        </>
                      </div>
                    </div>
                  </div>

                  <div className="md:flex items-center justify-center flex-col"></div>
                </CardContent>

                <CardContent className="grid gap-4  mt-4 ">
                  <Separator className="my-2" />
                </CardContent>

                <div className="flex justify-center px-16">
                    {
                        planData['is_report_success']?(
                            <Button variant="pp" className="text-[#333]" onClick={()=>{
                                navigate("/report?id="+planData['report_id'])
                            }}>
                                <span className="px-3">
                                    View Report
                                </span>
                            </Button>
                        ):''
                    }
                    {
                        planData['is_new']?(
                            <Button variant="pp" className="text-[#333]" onClick={()=>{
                                generateReport(planData['id'])
                            }}>
                                <span className="px-3">
                                    Generate Report {subscriptionData?'':<>&#128274;</>}
                                </span>
                            </Button>
                        ):''
                    }
                </div>

              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(PlanDetails);
