import * as React from "react";
import { Theme, Urls } from "../../utils/AppConfig";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/modal";
import { Link } from "@nextui-org/react";
import { Button } from "@nextui-org/react";
import { connect, useDispatch } from "react-redux";
import { togglePaywallDialog } from "../../actions";
import { fetchGet } from "../../utils/helpers";

const mapStateToProps = (state) => {
  return {
      data: state.DataReducer
  }
}


const RightComponent = (props) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const CrossLeaseModal = useDisclosure();
  const dispatch = useDispatch();
  const subscriptionData = props.data.currentSubscription;

  const [clientDetails, setClientDetails] = React.useState("");
  const [clientUrl, setClientUrl] = React.useState("");
  const [ratings, setRatings] = React.useState(null);
  const [listings, setListings] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [allRatings, setAllRatings] = React.useState({});




  React.useEffect(() => {

    if(!props.data.user){
      return;
    }

    var usr_data = {
      client_id: props.data.user.id,
      client_name: props.data.user.client_name,
      client_email: props.data.user.client_email,
      client_phone: props.data.user.client_phone,
    };

    var encoded = btoa(JSON.stringify(usr_data));
    setClientDetails(encoded);
    setClientUrl('https://projectx.nz/query?token='+encoded);

    if(props.data.currentParcelId && !isLoading && !allRatings[props.data.currentParcelId]){
      loadRatingsData(props.data.currentParcelId);
    }
    if(props.data.currentParcelId){
      loadListingData(props.data.currentParcelId);
    }

  }, [props]);

  const loadRatingsData = async (parcelId) => {
    try{
      setLoading(true)
      let ratingRes = await fetchGet(Urls.GetRatings + "?parcelId=" + parcelId);
      setLoading(false)
      if(!ratingRes.error){
        setRatings(ratingRes.data);
        let _aR = {
          ...allRatings
        }
        _aR[parcelId] = ratingRes.data
        setAllRatings(_aR);
      }
    }catch(err){
      setRatings(null);
    }
  }

  const loadListingData = async (parcelId) => {
    if(!subscriptionData){
      return;
    }
    try{
      let listingRes = await fetchGet(Urls.GetParcelListing + "?parcelId=" + parcelId);
      console.log(listingRes)
      if(!listingRes.error){
        setListings(listingRes.data);
      }
    }catch(err){
      setListings(null);
    }
  }

  return <div style={{cursor: !props.subscription?'pointer':'default'}} onClick={()=>{
    if(!props.subscription){
      //TODO
      dispatch(togglePaywallDialog(true, false, true));
    }
  }}>

    <div className="pt-4 pl-4 pr-4 text-[#00ff99]">
      <div style={{padding: '8px', border: '2px solid #00ff99'}}>
      
      {
        !listings?'Property is not listed':<>
        <h6 style={{textAlign: 'center'}}>⚠️Property is currently listed for sale</h6>
          {
            [
              ["Status", listings['listingstatus']==='active'?'Active':'NA'],
              ["Listed on", new Date(listings['listdate']).toDateString()],
              ["Listing price", "NZD " + listings['listprice']],
              ["Bed rooms", listings['bedrooms']],
              ["Bathrooms", listings['bathrooms']],
              ["Car spaces", listings['carspaces']],
              ["Floor area (sq.m)", listings['floorarea']===0?"-":listings['floorarea']],
              ["Pricing", listings['pricingmethod']]
            ].map(element=>{
              return <Row container>
              <Col item xs={6}>
                <p className="subtitle2">
                  {element[0]}
                </p>
              </Col>
              <Col item xs={6}>
                <p className={'caption text-[#ffffff]'} >{element[1]}</p>
              </Col>
            </Row>
            })
          }
        </>
      }
      </div>
    </div>


    <div style={{filter: !props.subscription?'blur(10px)':''}}>

        <div className="m-3 ">
          <div className="py-2 justify-center text-[#00ff99] font-bold flex">Flood Risk Rating</div>

          <div className=" flex p-2 text-center font-bold bg-gradient-to-r from-[#52C755] via-[#33A036,yellow,orange] to-red-600 h-[20px]" >
          <div
            className="absolute top-1/2 -translate-y-1/2 w-4 h-4 bg-black rounded-full border-2 border-white shadow-md"
            style={{ left: `${ratings?((ratings.flood) * 10):0}%`, position: 'relative' }}
          />
          </div>
          <div className="flex justify-between text-white" style={{ fontSize: 12 }}>
            <div>Low</div>
            <div>Moderate</div>
            <div>Severe</div>
          </div>
        </div>

        <div className="m-3 ">
          <div className="py-2 justify-center text-[#00ff99] font-bold flex">Serviceability</div>

          <div className=" flex p-2 text-center font-bold bg-gradient-to-r from-red-600 via-[orange,yellow,#33A036] to-[#52C755] h-[20px]" >
          <div
            className="absolute top-1/2 -translate-y-1/2 w-4 h-4 bg-black rounded-full border-2 border-white shadow-md height-[20px]"
            style={{ left: `${ratings?(ratings.service * 10):0}%`, position: 'relative' }}
          />
          </div>
          <div className="flex justify-between text-white" style={{ fontSize: 12 }}>
            <div className="">1</div>
            <div>2</div>
            <div>3</div>
            <div>4</div>
            <div>5</div>
          </div>
        </div>

        <div className="m-3 ">
          <div className="py-2 justify-center text-[#00ff99] font-bold flex">Contour Rating</div>

          <div className=" flex p-2 text-center font-bold bg-gradient-to-r from-[#4b9d0f] via-[#d6e554,#ffee5d,#f8ad26,#94651e] to-[#ffffff] h-[20px]" >
          <div
            className="absolute top-1/2 -translate-y-1/2 w-4 h-4 bg-black rounded-full border-2 border-white shadow-md height-[20px]"
            style={{ left: `${ratings?(ratings.contour * 10):0}%`, position: 'relative' }}
          />
          </div>
          <div className="flex justify-between text-white" style={{ fontSize: 12 }}>
            <div className="">1</div>
            <div>2</div>
            <div>3</div>
            <div>4</div>
            <div>5</div>
          </div>
        </div>
        
    </div>

    <div className="m-3 border-2 border-[#00ff99]">
      <div className="py-2 justify-center  text-[#00ff99] font-bold flex">Zone Regulations</div>
      <div className="w-full">
        <div className="text-base justify-between  text-[#00ff99] rounded flex p-2 h-full  items-center">
          Building Coverage
          <span className=" px-3 text-white " style={{filter: !props.subscription?'blur(5px)':''}}>{props.data.zoneData?props.data.zoneData['buildingCoverageArea'].toFixed(1) + ' sq.m':'...'}</span>
        </div>
      </div>
      <div className="w-full">
        <div className="text-base justify-between  text-[#00ff99] rounded flex p-2 h-full items-center ">
          Landscaping
          <span className=" px-3 text-white " style={{filter: !props.subscription?'blur(5px)':''}}>{props.data.zoneData && props.data.zoneData['zoneRegulation']['landscape']?props.data.zoneData['zoneRegulation']['landscape']['maxPercent'] * 100 + "%":''}</span>
        </div>
      </div>
      <div className="w-full">
        <div className="text-base justify-between text-[#00ff99] rounded flex p-2 h-full items-center ">
          Setbacks
          <span className=" px-3 text-white " style={{filter: !props.subscription?'blur(5px)':''}}>{props.data.zoneData && props.data.zoneData['zoneRegulation']['yardControls']?props.data.zoneData['zoneRegulation']['yardControls']['front']+'m front & '+props.data.zoneData['zoneRegulation']['yardControls']['side']+'m side':''}</span>
        </div>
      </div>
      <div className="w-full">
        <div className="text-base justify-between text-[#00ff99] rounded flex p-2 h-full items-center ">
          Max. no of floors
          <span className=" px-3 text-white " style={{filter: !props.subscription?'blur(5px)':''}}>{props.data.zoneData?props.data.zoneData['zoneRegulation']['maxNoOfFloors']:''}</span>
        </div>
      </div>
    </div>


    <div className="py-2 justify-center text-[#00ff99] font-bold flex text-center" style={{ fontSize: 20 }}>What can I do with my plot?</div>
    <div className="w-full">
      <div className=" rounded flex px-4 py-3 h-full items-center  justify-between">
        <div className="text-[#00ff99] font-bold text-md xl:text-lg">Change of Use</div>
        <span className="text-xl px-3 text-white font-bold" style={{filter: !props.subscription?'blur(5px)':''}}>{props.subscription?<Button className="bg-[#00ff99] text-black" onPress={onOpen} >Request Info</Button>:''}</span>
      </div>
    </div>
    <div className="w-full">
      <div className=" rounded flex px-4 py-3 h-full items-center  justify-between">
        <div className="text-[#00ff99] font-bold text-md xl:text-lg">Cross-lease to Freehold</div>
        <span className="text-xl px-3 text-white font-bold" style={{filter: !props.subscription?'blur(5px)':''}}>{props.subscription?<Button className="bg-[#00ff99] text-black" onPress={CrossLeaseModal.onOpen}>Details</Button>:''}</span>
      </div>
    </div>
    <div className="w-full">
      <div className=" rounded flex px-4 py-3 h-full items-center  justify-between">
        {/* <div className="text-[#00ff99] font-bold text-md xl:text-lg">Selling Time</div> */}
        {/* <span className="text-md px-3 text-white font-bold" style={{filter: !props.subscription?'blur(5px)':''}}>Coming Soon</span> */}
      </div>
    </div>
    <div className="py-2 justify-center text-[#00ff99] font-bold flex text-center" style={{ fontSize: 20 }}>Any issues on your property ?</div>

    <div className="  text-center px-3 pb-5 text-white" >
    Any unconsented works on your property? We can help you with that – register the scope of unconsented works here <span className="text-[#00ff99]"><Link className="text-[#00ff99]" href={clientUrl} target="_blank">(www.projectx.nz)</Link></span>

    </div>


    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent className="z-50">
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-[#00ff99]">Change of Use</ModalHeader>
            <ModalBody>
              <p className="text-[#ff8800]">Coming Soon</p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              {/* <Button color="primary" onPress={onClose}>
                Action
              </Button> */}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>

    <Modal size="full" scrollBehavior="inside" isOpen={CrossLeaseModal.isOpen} onOpenChange={CrossLeaseModal.onOpenChange}>
      <ModalContent className="z-50">
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-[#00ff99]">Cross lease to Free hold</ModalHeader>
            <ModalBody>
              <p className="text-[#eee]">
              <p>
              All the information and scenario representation is BASED ON ALL THE NEIGHBORING PROPERTIES APPROVING THE CROSS-LEASE change to Freehold.
              </p>

              <p>
              &nbsp;&nbsp;&nbsp;&nbsp;The process involves obtaining subdivision consent from the Council, which will allow for new titles to be raised after the process. Usually, this process is relatively simple depending on the servicing and layout of your current cross-lease arrangement. Plot Potential has an experienced consultant and network in working through any issues with both our clients and the Council to achieve the best possible outcome, as quickly as possible.
              <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;Our approach is that a cross-lease conversion to fee simple is a change in the form of ownership and not a new subdivision. The reasoning behind this strategy is that it enables the existing servicing, driveway, and other existing physical structures to remain in their current state. In some circumstances due to a variety of factors, the Council may require some upgrades, such as separating water supply, utilities, and/or other servicing or driveway arrangements. Should the Council require a full servicing assessment including the separation of drainage, Plot Potential can do that work either on quote on that work if fixed fees are preferred.
              <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;It is assumed that the new boundaries will follow the existing flat plan exclusive use boundaries. If changes are proposed, these should be mutually agreed upon between all cross-lease parties before consent is applied for. Council may have limitations around how much the boundaries are allowed to change as compared to the existing cross-lease boundaries.
              <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;The process from engagement to new titles being issued could range from 4-8 months, and potentially longer if civil upgrades are needed. A lot of this time is taken up by Council and LINZ processing, and we endeavour to do our best to speed up this process wherever possible.
              <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;We trust that the information above answers your request and query at this stage.
              </p>

              <h5 className="text-[#ff9900]">Stage 1</h5>
              The following tasks are required to prepare and lodge the application with the Council.
              <br/><br/><p>
                <span className="text-[#00ff99]">Task A:</span> Administration, Review, and Site Visit – Carry out site visits. Engage with and liaise with project team members/ co-consultants / clients, as required. Includes review of specialist inputs to confirm completeness/accuracy for resource consent lodgement purposes.
                <br/>
              PP Cost: $1000</p>
              <p>
              <span className="text-[#00ff99]">Task B:</span>  Planning: Planning Report – Completion of Assessment of Environmental Effects (AEE), associated planning documentation, and lodgement with Council.
              <br/>
              $3,250.00</p>

              <p>
              <span className="text-[#00ff99]">TASK C:</span>  Surveying: Topographic Survey – Preparation of a Topographic Site Survey showing existing site layout, indicative boundaries, and easements, for distribution to the consultant team as required.
              <br/>PP COST: $2,720.00</p>

              <p><span className="text-[#00ff99]">TASK D:</span> Surveying: Scheme Plan – Preparation of a Scheme Plan showing the proposed site layout, boundaries, and easements.
              PP COST: $1,500.00</p>

              <p><span className="text-[#00ff99]">TASK E:</span>  Engineering: Infrastructure Inspections – Prepare Infrastructure Inspection quotes, and liaison with CCTV and/or GPR providers to inform the Scheme Plan.
              <br/>PP COST: $840.00</p>
              
              <p><span className="text-[#00ff99]">TASK F:</span>  Engineering: Site Servicing Plan – Preparation of a Site Servicing Plan showing site layout, areas, and services to wastewater, water supply, and stormwater networks.
              <br/>PP COST: $1,300.00</p>


              <p><span className="text-[#00ff99]">TASK G:</span> Engineering: Property File check – Obtain and review the site property file to confirm servicing and additional background information. Includes review of information received.
              </p>*Cost for a single property file only. The cost for additional property files is dependent on the volume of information.
              <br/>PP Cost: $500 per property.
              <p>
              <br/>
              <span className="text-[#00ff99]">Task H (optional):</span> Additional site visits to survey CCTV pegs and drainage assets: – Once CCTV inspection is complete our surveyors can return to the site to survey the location of CCTV pegs to ensure the correct location of pipes is captured. Survey of additional drainage assets beyond the Topo extent to inform the engineers’ servicing design (noting access to neighbouring properties is typically required, which may result in multiple visits and preparation of letter(s) requesting site access).
              </p>
              PP Cost hourly: $300 P/H

              <br/>
              <br/>
              <h5 className="text-[#ff9900]">Stage 2</h5>
              <p>
              Sign off for Survey plan and Land Transfer
</p>
<p>
<span className="text-[#00ff99]">TASK A:</span> Surveying: Survey Plan Approval (S223 certificate)* – Prepare a new subdivision survey plan and submit it to the Council for s223 approval.
<br/>
              PP COST: $3000</p>
              <p>
              <span className="text-[#00ff99]">TASK B:</span> Surveying: Land Transfer Survey* – Peg new boundaries, provide easements as required, and submit survey plan and reports to LINZ for approval. Does not include Council or LINZ fees.
</p><p>
<span className="text-[#00ff99]">Task C:</span> 224C (new titles) Liaise with developer, contractor, consultants and Council to obtain and collate consent condition completion documents. Prepare 224C application and lodge with Council.
</p>

              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Close
              </Button>
              {/* <Button color="primary" onPress={onClose}>
                Action
              </Button> */}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>

  </div>
};

export default connect(mapStateToProps)(RightComponent);