import { connect, useDispatch } from "react-redux";
import { setUser, toggleLoader } from "../actions";
import HomeHeader from "../components/HomeHeader";
import SignInForm from "../components/SignIn/SignInForm";
import '../styles/signin.css';
import { validateToken } from "../utils/helpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import Cursor from '../components/Cursor/Cursor';

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer
    }
}

const SignInPage = (props) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    let returnUrl = searchParams.get("returnUrl");
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            const user = await validateToken();

            if (user) {
                dispatch(setUser(user));
                if (!returnUrl) {
                    navigate("/", { replace: true });
                    return;
                }
                navigate(returnUrl, { replace: true });
            } else {
                dispatch(setUser(null));
            }
        };

        checkUser();
    }, [props.data.token]);

    return (
        <>
            <Cursor />
            <HomeHeader />
            <SignInForm />
        </>
    );
};

export default connect(mapStateToProps)(SignInPage);