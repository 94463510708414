import { useEffect, useState } from "react";
import { fetchPost } from "../../utils/helpers";
import ActivePlan from "./subscription/ActivePlan";
import { connect, useDispatch } from "react-redux";
import { setCurrentSubscription, toggleLoader } from "../../actions";
import { Urls } from "../../utils/AppConfig";
import SubscriptionSection from "../SubscriptionSection";

const mapStateToProps = (state) => {
  return {
      data: state.DataReducer
  }
}

const SubscriptionTab = (props) => {

  const subscriptionData = props.data.currentSubscription;
  const dispatch = useDispatch();

  useEffect(()=>{
    const checkActiveSubscription = async () => {
        dispatch(toggleLoader(true, "Checking for subscriptions..."));
        try{
            let response = await fetchPost(Urls.LoadUserSubscription)
            dispatch(toggleLoader(false));
            if (response.error || !response.data.hasSubscription) {
                console.log("No subscription");
                dispatch(setCurrentSubscription(null));
            } else {
                dispatch(setCurrentSubscription(response.data))
            }
        }catch(err){
            console.log(err);
        }
    }
    checkActiveSubscription();
  }, [])



  return (
    !subscriptionData ? (
      <>
        <SubscriptionSection/>
      </>
    ) : (
      <>
        <ActivePlan subscriptionData={subscriptionData} />
      </>
    )
  )
}

export default connect(mapStateToProps)(SubscriptionTab);