import { Card, CardContent, CardFooter, CardTitle, CardHeader } from "../../shadcn/components/ui/card"
import { Separator } from "../../shadcn/components/ui/separator"
import { Button } from "../../shadcn/components/ui/button"
import { useNavigate } from "react-router-dom"

export default function Subscription(props) {

  const navigate = useNavigate();

  const HomeBtn = () => {
    return <div onClick={() => {
      navigate("/home")
    }}>
      <Button variant="pp" className="text-black font-bold" style={{backgroundColor: '#ff2222'}}>
        Home
      </Button>
    </div>
  }
  const SubscriptionsBtn = () => {
    return <div onClick={() => {
      navigate("/my-subscription")
    }}>
      <Button variant="pp" className="text-black font-bold">
      My Subscription
      </Button>
    </div>
  }

  let paymentData = props.paymentData;
  if (!paymentData) {
    return '';
  }
  if (paymentData.error) {
    return <div className="flex flex-col items-center justify-center gap-4 p-2">
      <div className="flex flex-col items-center gap-2 text-center">
        <XIcon className="h-12 w-12 text-red-600" />
        <h1 className="font-semibold text-3xl text-white">Payment Failed</h1>
        <p className="max-w-[600px] text-gray-500 md:text-xl/tight dark:text-gray-400">
          Server error. Please, try again later.
        </p>
      </div>
      <HomeBtn />
    </div>
  } else {
    if (paymentData.data.isSubscribed) {
      return (
        <div className="flex flex-col items-center justify-center gap-4 p-2">
          <div className="flex flex-col items-center gap-2 text-center">
            <CircleCheckIcon className="h-12 w-12 text-[#00ff99]" />
            <h1 className="font-semibold text-3xl text-white">Payment successful</h1>
            <p className="max-w-[600px] text-gray-500 md:text-xl/tight dark:text-gray-400">
              Your subscription for our <b style={{color: "#00FF99"}}>{paymentData.data.plan['productDescription']}</b> plan has been activated.
            </p>
          </div>

          <Card className="w-full max-w-lg p-0 text-white border-none bg-[#111111] border-2">
            <CardHeader className="flex justify-center items-center">
              <CardTitle className="text-[#00ff99]">Payment Details</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              <div className="flex items-center justify-between">
                <div className="text-[#00ff99] font-extrabold ">Subscription Plan</div>
                <div className="font-medium">{paymentData.data.plan['productDescription']}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#00ff99] font-extrabold ">Amount Paid</div>
                <div className="font-medium">NZ$ {paymentData.data.plan['price']}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#00ff99] font-extrabold ">Reference ID</div>
                <div className="font-medium">#{paymentData.data.subscription['subscription_ref']}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#00ff99] font-extrabold ">Transaction Date</div>
                <div className="font-medium">{new Date(paymentData.data.subscription['created_on']).toLocaleDateString('in')}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#00ff99] font-extrabold ">Renewal Date</div>
                <div className="font-medium">{new Date(paymentData.data.subscription['expires_on']).toLocaleDateString('in')}</div>
              </div>
              <Separator className="my-2" />
              <div className="grid gap-1">
              <div style={{color: "#00ff99", textAlign: "left"}}>For any queries, please contact:</div>
                <div className="flex items-center gap-4 ">
                  {/* <div className="font-medium"><Mail color="#00ff99" /></div> */}
                  <div className="font-bold"><u>Email:</u> contact@plotpotential.co.nz</div>
                </div>
                <div className="flex items-center gap-4">
                  {/* <div className="font-medium"><Phone color="#00ff99" /></div> */}
                  <div className="font-bold"><u>Phone:</u> +64 22 370 7760</div>
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex w-full gap-5 p-4 md:p-6 items-center justify-center">
              <HomeBtn />
              <SubscriptionsBtn />
            </CardFooter>
          </Card>
        </div>
      )
    } else {
      return (
        <div className="flex flex-col items-center justify-center gap-4 p-2">
          <div className="flex flex-col items-center gap-2 text-center">
            <XIcon className="h-12 w-12 text-red-600" />
            <h1 className="font-semibold text-3xl text-white">Payment failed</h1>
            <p className="max-w-[600px] text-gray-500 md:text-xl/tight dark:text-gray-400">
              Your subscription for our <b style={{color: "#00FF99"}}>{paymentData.data.plan['productDescription']}</b> plan has failed. Please, try again later.
            </p>
          </div>

          <Card className="w-full max-w-lg p-0 text-white border-none bg-[#111111] border-2">
            <CardHeader className="flex justify-center items-center">
              <CardTitle className="text-[#00ff99]">Payment Details</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              <div className="flex items-center justify-between">
                <div className="text-[#00ff99] font-extrabold ">Subscription Plan</div>
                <div className="font-medium">{paymentData.data.plan['productDescription']}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#00ff99] font-extrabold ">Amount</div>
                <div className="font-medium">NZ$ {paymentData.data.plan['price']}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#00ff99] font-extrabold ">Reference ID</div>
                <div className="font-medium">#{paymentData.data.subscription['subscription_ref']}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-[#00ff99] font-extrabold ">Transaction Date</div>
                <div className="font-medium">{new Date(paymentData.data.subscription['created_on']).toLocaleDateString('in')}</div>
              </div>
              <Separator className="my-2" />
              <div className="grid gap-1">
              <div style={{color: "#00ff99", textAlign: "left"}}>For any queries, please contact:</div>
                <div className="flex items-center gap-4 ">
                  {/* <div className="font-medium"><Mail color="#00ff99" /></div> */}
                  <div className="font-bold"><u>Email:</u> contact@plotpotential.co.nz</div>
                </div>
                <div className="flex items-center gap-4">
                  {/* <div className="font-medium"><Phone color="#00ff99" /></div> */}
                  <div className="font-bold"><u>Phone:</u> +64 22 370 7760</div>
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex w-full gap-5 p-4 md:p-6 items-center justify-center">
              <HomeBtn />
            </CardFooter>
          </Card>
        </div>
      )
    }
  }

}

function CircleCheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="m9 12 2 2 4-4" />
    </svg>
  )
}


function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {/* <path d="M18 6 6 18" />
      <path d="m6 6 12 12" /> */}
      <path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ff0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
  )
}



