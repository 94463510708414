import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import * as React from 'react';
import LandingPage from './pages/LandingPage';
import MapPage from './pages/MapPage';
import Loader from './components/Loader';
import { connect, useDispatch } from 'react-redux';
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import { Navigate } from 'react-router-dom';

import AlertMessage from './components/AlertMessage'
import PaymentWaitingPage from './pages/PaymentWaitingPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsConditionsPage from './pages/TermsConditionsPage'
import SignInPage from './pages/SignInPage';
import HomePage from './pages/HomePage';
import OrdersPage from './pages/OrdersPage';
import ProfilePage from './pages/ProfilePage';
import OrderAutoReport from './components/OrderAutoReport';
import ShowSamplePlans from './components/ShowSamplePlans';
import ReportPage from './pages/ReportPage';
import PaymentDetailsPage from './pages/PaymentDetailsPage';
import SignUpPage from './pages/SignUpPage';
import DownloadManualReport from './pages/DownloadManualReport';
import AboutUsPage from './pages/AboutUsPage';
import CrmInfoPage from './pages/CrmInfoPage';
import ReportsInfoPage from './pages/ReportsInfoPage';
import ClientDetailsPage from './pages/ClientDetailsPage';
import TeamPage from './pages/TeamPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ReportLoader from './components/ReportLoader';
import QuotationPage from './pages/QuotationPage';
import ProductGuidePage from './pages/ProductGuidePage';
import InfoPage from './pages/InfoPage';
import GenerateLicense  from './components/GenerateLicense';
import BookDemoPage from './pages/BookDemo';
import SubscriptionPage from './pages/SubscriptionPage';
import ReportsPage from './pages/ReportsPage';
import MySubscriptionPage from './pages/MySubscriptionPage';
import PlotWorksPage from './pages/PlotWorksPage';
import ApplicationsPage from './pages/ApplicationsPage';
import QuotationsPage from './pages/QuotationsPage';
import ManualReportsPage from './pages/ManualReportsPage';
import ManualReportPage from './pages/ManualReportPage';
import QuoteContractsPage from './pages/QuoteContractsPage';
import QuoteContractPage from './pages/QuoteContractPage';
import { fetchPost, validateToken } from './utils/helpers';
import { setCurrentSubscription, setToken, setUser, toggleLoader } from './actions';
import ProgressLoader from './components/ProgressLoader';
import NotificationPage from './pages/NotificationPage';
import PlanPage from './pages/PlanPage';
import PlanDetailsPage from './pages/PlanDetailsPage';
import { Urls } from './utils/AppConfig';
import ForgotPasswordPage from './pages/ForgotPasswordPage';

// library.add(faMap, faCaretRight, faCaretLeft)

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer,
        map: state.MapReducer,
        dialog: state.DialogReducer
    }
}

function App(props) {
    const [validatingToken, setValidatingToken] = React.useState(false);
    let curUrl = window.location.href.replace(/https?:\/\//i, "").replace(window.location.host, '');
    curUrl = curUrl.split("#")[0];
    curUrl = curUrl.split("returnUrl=").slice(-1).pop()
    // console.log(curUrl);
    const dispatch = useDispatch();

    const urlParams = new URLSearchParams(window.location.search);
    const reinzCode = urlParams.get('code');
    if(reinzCode && props.data.user && props.data.user.client_email.indexOf("plotpotential.co.nz") > -1){
        console.log("Redirect...");
        window.location.href = "http://localhost/reinz?code=" + reinzCode;
    }

    React.useEffect(()=>{
        let token = window.localStorage.getItem('token');
        if(!props.data.token && token){
            dispatch(setToken(token));
            return;
        }
    }, []);

    React.useEffect(() => {
        if(!props.data.token){
            return;
        }
        if(props.data.user){
            return;
        }
        if(validatingToken){
            return;
        }
        console.log("Checking user login...");
        setValidatingToken(true)
        dispatch(toggleLoader(true));
        validateToken().then(user => {
            setValidatingToken(false);
            dispatch(toggleLoader(false));
            if (user) {
                dispatch(setUser(user));
            } else {
                window.localStorage.removeItem("token");
                dispatch(setToken(null));
                dispatch(setUser(null));
            }
        })
    }, [props.data.token]);

    React.useEffect(()=>{
        const checkActiveSubscription = async () => {
            dispatch(toggleLoader(true, "Checking for subscriptions..."));
            try{
                let response = await fetchPost(Urls.LoadUserSubscription)
                dispatch(toggleLoader(false));
                if (response.error || !response.data.hasSubscription) {
                    console.log("No subscription");
                    dispatch(setCurrentSubscription(null));
                } else {
                    dispatch(setCurrentSubscription(response.data))
                }
            }catch(err){
                console.log(err);
            }
        }
        if(props.data.user){
            checkActiveSubscription();
        }
    }, [props.data.user])

    const router = createBrowserRouter([
        {
            path: "/",
            element: <LandingPage />,
        },
        {
            path: "/map",
            element: Boolean(props.data.user) || true ? true?<MapPage />:<LandingPage/> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/payment",
            element: Boolean(props.data.user) ? <PaymentWaitingPage paymentFor='order' /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/subscription",
            element: Boolean(props.data.user) ? true?<PaymentWaitingPage paymentFor='subscription' />:<LandingPage/> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/notifications",
            element: Boolean(props.data.user) ? true?<NotificationPage />:<LandingPage/> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/plans",
            element: Boolean(props.data.user) ? true?<PlanPage />:<LandingPage/> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/plan",
            element: Boolean(props.data.user) ? true?<PlanDetailsPage />:<LandingPage/> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/report",
            element: Boolean(props.data.user) ? true?<ReportPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/paymentDetails",
            element: Boolean(props.data.user) ? true?<PaymentDetailsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />

        },
        {
            path: "/privacy-policy",
            element: Boolean(props.data.user) ? <PrivacyPolicyPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/signIn",
            element: <SignInPage />
        },
        {
            path: "/signUp",
            element: !Boolean(props.data.user) ? <SignUpPage /> : <Navigate to={{ pathname: '/home' }} />
        },
        {
            path: "/home",
           element: Boolean(props.data.user) ? true?<HomePage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/orders",
            element: Boolean(props.data.user) ? true?<OrdersPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/profile",
             element: Boolean(props.data.user) ? true?<ProfilePage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/reports",
           element: Boolean(props.data.user) ? true?<ReportsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/my-subscription",
           element: Boolean(props.data.user) ? true?<MySubscriptionPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/downloadMR",
            element: Boolean(props.data.user) ? <DownloadManualReport /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/policy",
            element: Boolean(props.data.user) ? <PrivacyPolicyPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/terms",
            element: Boolean(props.data.user) ? <TermsConditionsPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/aboutus",
            element: Boolean(props.data.user) ? true?<AboutUsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/aboutreports",
            element: Boolean(props.data.user) ? true?<ReportsInfoPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/aboutcrm",
            element: Boolean(props.data.user) ? true?<CrmInfoPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/clientInfo",
            element: Boolean(props.data.user) ? true?<ClientDetailsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/team",
            element: Boolean(props.data.user) ? <TeamPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/forgotPassword",
             element: <ForgotPasswordPage />
        },
        {
            path: "/resetPassword",
             element: <ResetPasswordPage />
        },
        {
            path: "/productGuide",
            element: Boolean(props.data.user) ? true?<ProductGuidePage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/info",
             element: <InfoPage />
        },
        {
            path: "/pdf",
            element: Boolean(props.data.user) ? true?<GenerateLicense />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/bookDemo",
             element: <BookDemoPage />
        },
        {
            path: "/pricing",
             element: <SubscriptionPage />
        },
        {
            path: "/plotworks/manualReport",
            element: Boolean(props.data.user) ? true?<ManualReportsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/plotworks/quotation",
            element: Boolean(props.data.user) ? true?<QuotationsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/plotworks/application",
            element: Boolean(props.data.user) ? true?<ApplicationsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/plotworks",
            element: Boolean(props.data.user) ? true?<PlotWorksPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/plotworks/quotation/viewQuote",
            element: Boolean(props.data.user) ? true?<QuotationPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/plotworks/manualReport/viewManualReport",
            element: Boolean(props.data.user) ? true?<ManualReportPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },

        {
            path: "/plotworks/contract",
            element: Boolean(props.data.user) ? true?<QuoteContractsPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },
        {
            path: "/plotworks/contract/viewQuoteContract",
            element: Boolean(props.data.user) ? true?<QuoteContractPage />:<LandingPage /> : <Navigate to={{ pathname: `/signIn`, search: `?returnUrl=${curUrl}` }} />
        },



    ]);

    return (
        <>
            <RouterProvider router={router} key={window.location.pathname} />
            <Loader />
            <ProgressLoader />
            <ReportLoader />
            {props.dialog.manualOrderDialog.flag ? (
                <OrderAutoReport
                    selectedAddress={{
                        name: props.dialog.manualOrderDialog.parcel ? props.dialog.manualOrderDialog.parcel.address : "",
                        id: props.dialog.manualOrderDialog.parcel ? props.dialog.manualOrderDialog.parcel.addressId : ""
                    }}
                />) : ''}
            <AlertMessage />
        </>
    );
}
export default connect(mapStateToProps)(App);