import * as React from 'react';
import EmpLogoPng from '../images/PP.png'
import { Theme } from '../utils/AppConfig';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { connect, useDispatch } from 'react-redux';
import { setCurrentSubscription, setUser } from '../actions';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer
    }
}

const AppHeader = (props) => {
    const dispatch = useDispatch();

    return <>
        <Navbar data-bs-theme="dark" expand="lg" fixed='top' style={{ backgroundColor: '#000000EE' }}>
            <Container>
                <Navbar.Brand href="/" style={{ color: Theme.PrimaryLogo, margin: 'initial' }}>
                    <Link to={'/'}>
                        <img
                            alt=""
                            src={EmpLogoPng}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />
                        {' '}
                        <span style={{ fontFamily: 'Verdana, sans-serif' }}>PlotPotential</span>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" style={{ fontFamily: 'Verdana, sans-serif' }}>
                        <Nav.Link href="#services">Services</Nav.Link>
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                        <Nav.Link>
                            <Link to={'privacy'}>
                                Privacy Policy
                            </Link>
                        </Nav.Link>
                        <Nav.Link href="terms">
                            <Link to={'terms'}>
                                Terms & Conditions
                            </Link>
                        </Nav.Link>
                    </Nav>
                    <Nav>
                        {
                            !Boolean(props.data.user) ? (
                                <>
                                    <Nav.Link href="signIn"><Button variant="outline-success">Sign In</Button></Nav.Link>
                                    <Nav.Link href="signUp"><Button variant="outline-success">Sign Up</Button></Nav.Link>
                                </>
                            ) : (
                                <>
                                    {/* <Nav.Link><Button variant="outline-success">
                                        <Link to={'/home'}>Home</Link>
                                    </Button></Nav.Link> */}
                                    <Nav.Link href="#"><Button onClick={() => {
                                        dispatch(setUser(null));
                                        dispatch(setCurrentSubscription(null));
                                        window.localStorage.removeItem('token');
                                    }} variant="outline-success">Log Out</Button></Nav.Link>
                                </>
                            )
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
}

export default connect(mapStateToProps)(AppHeader);