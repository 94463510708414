// /**
//  * v0 by Vercel.
//  * @see https://v0.dev/t/bvVVDCNw9rK
//  * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
//  */
import { Card, CardContent, CardFooter, CardTitle, CardHeader } from "../../shadcn/components/ui/card"
import { Separator } from "../../shadcn/components/ui/separator"
import { Button } from "../../shadcn/components/ui/button"
import { fetchGet, fetchPost, toSlug } from "../../utils/helpers";
import { Urls } from "../../utils/AppConfig";
import { useNavigate } from "react-router-dom";

// import { Phone, Mail } from "lucide-react"

export default function Payment(props) {
  const navigate = useNavigate();
  console.log(props.paymentData);
  let token = window.localStorage.getItem('token');

  if (!props.paymentData || !props.paymentData.data.order) {
    return 'Error'
  }

  let paymentStatus = props.paymentData.data.order['status'] === 'ACTIVE';

  return (
    <div className="flex flex-col items-center justify-center  gap-4 p-2">
      {
        props.paymentData.error ? (
          <div className="flex flex-col items-center gap-2 text-center">
            <XIcon className="h-12 w-12 text-red-600" />
            <h1 className="font-semibold text-3xl text-white">Error</h1>
            <p className="max-w-[600px] text-gray-500 md:text-xl/tight dark:text-gray-400">
              Please, reload the page
            </p>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-2 text-center">
            {
              paymentStatus ? <CircleCheckIcon className="h-12 w-12 text-[#00ff99]" /> :
                <XIcon className="h-12 w-12 text-red-600" />
            }
            <h1 className="font-semibold text-3xl text-white">{paymentStatus ? 'Payment successful' : 'Payment failed'}</h1>
            <p className="max-w-[600px] text-gray-500 md:text-xl/tight dark:text-gray-400">
              {paymentStatus ? 'Your order has been confirmed and the report can be downloaded now.' : 'Please, retry'}
            </p>
          </div>
        )
      }

      <Card className="w-full max-w-sm p-0 text-white border-none bg-[#111111] border-2">
        <CardHeader className="flex justify-center items-center">
          <CardTitle className="text-[#00ff99]">Payment Details</CardTitle>
        </CardHeader>
        <CardContent className="grid gap-4">
          <div className="flex items-center justify-between">
            <div className="text-[#00ff99] font-extrabold ">Report Type</div>
            <div className="font-medium">Automatic Report</div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-[#00ff99] font-extrabold ">Amount</div>
            <div className="font-medium">NZ$ {(props.paymentData.data.amount / 100).toFixed(2)}</div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-[#00ff99] font-extrabold ">Transaction ID</div>
            <div className="font-medium">{props.paymentData.data.order['order_ref']}</div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-[#00ff99] font-extrabold ">Transaction Date</div>
            <div className="font-medium">{props.paymentData.data.order['created_on']}</div>
          </div>
          <Separator className="my-2" />
          <div className="grid gap-3">
            <div className="flex items-center gap-4 ">
              {/* <div className="font-medium"><Mail color="#00ff99" /></div> */}
              <div className="font-bold">contact@plotpotential.co.nz</div>
            </div>
            <div className="flex items-center gap-4">
              {/* <div className="font-medium"><Phone color="#00ff99" /></div> */}
              <div className="font-bold">+64 22 370 7760</div>
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex w-full gap-5 p-4 md:p-6 items-center justify-center">
          <div>
            {
              props.paymentData.error ? <Button variant="destructive" className="text-white font-bold" onClick={() => {
                window.location.reload();
              }}>
                Reload page
              </Button> : ''
            }
            {
              !props.paymentData.error && !paymentStatus ? <Button variant="destructive" className="text-white font-bold" onClick={() => {
                fetchGet(`${Urls.PayForOrder}?reportId=${props.paymentData.data.report['report_id']}`, token)
                  .then(paymentInfo => {
                    console.log("paymentInfo", paymentInfo);
                    if (paymentInfo.error) {
                      return window.alert("Payment error");
                    }
                    navigate(paymentInfo.data)
                  })
              }}>
                Retry
              </Button> : ''
            }
            {
              !props.paymentData.error && paymentStatus ? (<Button variant="pp" className="text-black font-bold" onClick={() => {
                // window.location.href = "/report?id=" + props.paymentData.data.report['report_id'];
                navigate("/report?id=" + props.paymentData.data.report['report_id'])
              }}>
                Go to Report
              </Button>) : ''
            }
          </div>
          <div>
            <Button variant="pp" className="text-black font-bold" onClick={() => {
              navigate("/home")
            }}>
              Home
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  )
}

function CircleCheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="m9 12 2 2 4-4" />
    </svg>
  )
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {/* <path d="M18 6 6 18" />
      <path d="m6 6 12 12" /> */}
      <path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ff0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
  )
}


