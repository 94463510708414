import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BgVidImg from "../../images/bgvidss.png";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Textarea,
} from "@nextui-org/react";
import { motion } from "framer-motion";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  SelectItem,
  Select,
  Divider
} from "@nextui-org/react";
import { fetchPost } from "../../utils/helpers";
import { Urls } from "../../utils/AppConfig";
import { useDispatch } from "react-redux";
import { toggleLoader, togglePaywallDialog } from "../../actions";
import { useEffect } from "react";
import AddressSearch from "../AddressSearch";
import { BookDemoIcon, SubscribeIcon,InvestmentIcon } from "../../icons/GeneralIcons";
import { useNavigate } from "react-router-dom";
import PaywallAlert from "../PaywallAlert";

const MainLandingSection = ({user, subscription}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const InvestmentModal = useDisclosure();
  const navigate = useNavigate();

  const dispatch = useDispatch();


const subscribeModal = () => {
  if(user){
    navigate('/my-subscription');
  }else{
    // navigate('/signIn');
    dispatch(togglePaywallDialog(true, false, true));
  }
}

  const openDemoModal = () => {
    onOpenChange();
  };
  const openInvestmentModal = () => {
    InvestmentModal.onOpenChange();
  };

  const closeInvestmentModal = () => {
    InvestmentModal.onClose();
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    const data = Object.fromEntries(new FormData(e.currentTarget));

    try {
      dispatch(toggleLoader(true));
      await fetchPost(Urls.RegisterEventForm, data).then((response) => {
        dispatch(toggleLoader(false));
        if (response.error) {
          return window.alert(
            "Failed to Submit the Form. Please, try again later."
          );
        } else {
          return window.alert("Submitted Successfully !");
        }
      });
    } catch (error) {
      console.error("Error Submitting Form", error);
      alert("Error Submitting Form. Try Again !");
    }
    if (data.form_type == "demo") {
      onOpenChange();
    } else if (data.form_type == "investment") {
      InvestmentModal.onClose();
    }
  };

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const hasDemo = urlParams.get('demo');
    const returnUrl = urlParams.get('returnUrl');
    if(returnUrl){
      return;
    }
    if(hasDemo){
      openDemoModal();
    }
  }, []);

  return (
    <>
      <header className="relative flex items-center justify-center min-h-screen overflow-hidden">
      {/* h-[calc(100vh-70px)]  */}
        <div
          className="z-10"
          style={{
            textAlign: "center",
            padding: 0,
            position: "absolute",
            top: 0,
            width: "100vw",
            height: "100vh",
          }}
        >
          <div
            elevation={0}
            className="content-card"
            style={{ textAnchor: "middle" }}
          >
            <Container>
              <Row>
                <Col sm={12} md={4}></Col>
                <Col
                  xs={12}
                  md={12}
                  style={{
                    paddingTop: 40,
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "block" }}>
                    <br />

                    <div className="">
                      <h1 className="head_font sm:text-4xl md:text-5xl lg:text-5xl xl:text-6xl" >
                        <strong>
                          Smart design and Build management platform
                        </strong>
                      </h1>
                      <p className="text-xl md:text-2xl pt-3 text-white sub_font font-extralight">
                        Unlock your property’s potential with Plot Potential
                      </p>
                      {/* <p className="text-2xl md:text-3xl pb-2 text-white sub_font_bold font-extrabold">
                        Starting from $49.
                      </p> */}
                    <br/>
                    <br/>
                    <Container>
                      <Row>
                        <Col xs={12} md={3}></Col>
                        <Col xs={12} md={6}>
                          <AddressSearch isInput={false} height={60} />
                        </Col>
                        <Col xs={12} md={3}></Col>
                      </Row>
                    </Container>

                    </div>
                    <br/>
                    <br/>

                    <div
                      className=" flex-row space-x-4  justify-center flex lg:hidden"
                      style={{ fontFamily: "Verdana, sans-serif" }}
                    >
                      <div className="w-full">
                        <Card className="hover:bg-gradient-to-r  from-[#49dd82] to-[#06b7d3] group border-2 border-[#00ff99] ">
                          <CardBody className="flex flex-col  text-black">
                          <div className="  ">
                              <motion.button
                                onClick={subscribeModal}
                                whileTap={{ scale: 0.8 }}
                                className="border-2 w-full justify-between flex-row flex rounded-2xl p-2 font-bold text-[#00ff99] border-[#00ff99]  group-hover:border-black  group-hover:text-black hover:bg-gradient-to-r from-pink-500 to-orange-500"
                              >
                                {
                                  !subscription?<div>
                                  Subscribe
                                  </div>:<div>
                                My Subscription
                                </div>
                                }
                                <div><SubscribeIcon width={30} height={30}/></div>
                              </motion.button>
                            </div>
                            <Divider className="h-[2px] rounded-lg bg-black" />
                            <div className="  ">
                            <motion.button
                              endContent={<SubscribeIcon />}
                                onClick={openInvestmentModal}
                                whileTap={{ scale: 0.8 }}
                                className="border-2 w-full justify-between flex-row flex rounded-2xl p-2 font-bold text-[#00ff99] border-[#00ff99]  group-hover:border-black  group-hover:text-black hover:bg-gradient-to-r from-pink-500 to-orange-500"
                              >
                                <div>
                                Investment
                                </div>
                                <div><InvestmentIcon width={30} height={30}/></div>
                              </motion.button>
                              </div>
                              <Divider className="h-[2px] rounded-lg bg-black" />
                              <div className="">
                              <motion.button
                              endContent={<SubscribeIcon />}
                                onClick={openDemoModal}
                                whileTap={{ scale: 0.8 }}
                                className="border-2 w-full justify-between flex-row flex rounded-2xl p-2 font-bold text-[#00ff99] border-[#00ff99]  group-hover:border-black  group-hover:text-black hover:bg-gradient-to-r from-pink-500 to-orange-500"
                              >
                                <div>
                                Book a demo
                                </div>
                                <div><BookDemoIcon width={30} height={30}/></div>
                              </motion.button>
                              </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>

                    <div
                      className=" flex-row space-x-4 py-4 justify-center hidden lg:flex"
                      style={{ fontFamily: "Verdana, sans-serif" }}
                    >
                       <div className="w-1/3">
                        <Card className=" hover:bg-gradient-to-r from-[#49dd82] to-[#06b7d3] group border-2 border-[#00ff99] ">
                          <CardHeader className="flex gap-3"></CardHeader>
                          <CardBody className="head_font2 text-xl flex items-center text-center font-extrabold group-hover:text-black">
                            <div>
                              {
                                !subscription?<p>Subscribe</p>:<p>Subscription</p>
                              }
                            </div>
                            <div>
                            <SubscribeIcon width={80} height={80} />
                            </div>
                          </CardBody>
                          <CardFooter className="flex justify-center items-center group-hover:text-black">
                            <motion.button
                              onClick={subscribeModal}
                              whileTap={{ scale: 0.8 }}
                              className="border-2 rounded-3xl p-2 group-hover:border-black font-bold group-hover:bg-gradient-to-r group-hover:from-pink-500 group-hover:to-orange-500  group-hover:text-white"
                            >
                              {
                                !subscription?'Click Here':'View My Subscription'
                              }
                            </motion.button>
                          </CardFooter>
                        </Card>
                      </div>

                      <div className="w-1/3">
                      <Card className=" hover:bg-gradient-to-r from-[#49dd82] to-[#06b7d3] group border-2 border-[#00ff99] ">
                          <CardHeader className="flex gap-3"></CardHeader>
                          <CardBody className="head_font2 text-xl flex items-center text-center font-extrabold group-hover:text-black">
                            <div>
                              <p>Investment </p>
                            </div>
                            <div>
                            <InvestmentIcon width={80} height={80} />
                            </div>
                          </CardBody>
                          <CardFooter className="flex justify-center items-center group-hover:text-black">
                            <motion.button
                              onClick={openInvestmentModal}
                              whileTap={{ scale: 0.8 }}
                              className="border-2 rounded-3xl p-2 group-hover:border-black font-bold group-hover:bg-gradient-to-r group-hover:from-pink-500 group-hover:to-orange-500  group-hover:text-white"
                            >
                              I’m Interested
                            </motion.button>
                          </CardFooter>
                        </Card>
                      </div>

                      <div className="w-1/3">
                      <Card className=" hover:bg-gradient-to-r from-[#49dd82] to-[#06b7d3] group border-2 border-[#00ff99] ">
                          <CardHeader className="flex gap-3"></CardHeader>
                          <CardBody className="head_font2 text-xl flex items-center text-center font-extrabold group-hover:text-black">
                            <div>
                              <p>Book a demo</p>
                            </div>
                            <div>
                              <BookDemoIcon width={80} height={80} />
                            </div>
                          </CardBody>
                          <CardFooter className="flex justify-center items-center group-hover:text-black">
                            <motion.button
                              onClick={openDemoModal}
                              whileTap={{ scale: 0.8 }}
                              className="border-2 rounded-3xl p-2 group-hover:border-black font-bold group-hover:bg-gradient-to-r group-hover:from-pink-500 group-hover:to-orange-500  group-hover:text-white"
                            >
                              I’m Interested
                            </motion.button>
                          </CardFooter>
                        </Card>
                      </div>
                    </div>

                    <br />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div className=" -z-0 top-0 left-0  object-cover absolute h-screen  w-screen">
        {/* h-[calc(100vh-72px)] */}
          <img
            alt="Plot Potential Demo"
            src={BgVidImg}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
      </header>

      <Modal
        isOpen={isOpen}
        placement="center"
        scrollBehavior="outside"
        className="bg-[#333]"
        backdrop="blur"
        size="3xl"
        isDismissable={false}
        onOpenChange={onOpenChange}
        style={{ fontFamily: "Verdana, sans-serif" }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-center text-white">
                <h3 className="text-center pt-3 text-[#00ff99] font-semibold">
                  Book a Demo
                </h3>
                Complete the form and we’ll get back to you soon.
              </ModalHeader>
              <Form onSubmit={onSubmitForm} validationBehavior="native">
                <div className="hidden">
                  <Input readOnly value={"demo"} name="form_type" />
                </div>
                <ModalBody className="text-white w-full">
                  <Input
                    label="Full Name"
                    labelPlacement="outside"
                    placeholder="Enter your name"
                    isRequired
                    name="full_name"
                  />
                  <Input
                    label="Email"
                    labelPlacement="outside"
                    placeholder="Enter your email"
                    isRequired
                    name="email"
                  />
                  <Input
                    label="Phone"
                    labelPlacement="outside"
                    placeholder="Enter your Phone"
                    isRequired
                    name="phone"
                  />
                  <Select
                    name="user_type"
                    labelPlacement="outside"
                    label="What best describes your profession?"
                    placeholder="Select"
                    classNames={{
                      listbox: ["mb-0 p-0"],
                    }}
                    isRequired
                  >
                    <SelectItem className="text-white" key={"Real Estate"}>
                      {"Real estate professional (e.g. agent, broker)"}
                    </SelectItem>
                    <SelectItem className="text-white" key={"Developer"}>
                      {"Developer (e.g. land developer, property investor)"}
                    </SelectItem>
                    <SelectItem className="text-white" key={"Homebuyer"}>
                      {
                        "Homebuyer or property owner exploring development opportunities"
                      }
                    </SelectItem>
                    <SelectItem className="text-white" key={"Media"}>
                      {"Media or press (e.g. journalist, blogger)"}
                    </SelectItem>
                    <SelectItem className="text-white" key={"Builder"}>
                      {"Builder / Building Companies"}
                    </SelectItem>
                    <SelectItem className="text-white" key={"Consultant"}>
                      {
                        "Consultant (Architect, Engineer, Planner, Traffic Consultant)"
                      }
                    </SelectItem>
                  </Select>
                  <Select
                    name="product"
                    labelPlacement="outside"
                    label="Demo Product"
                    placeholder="Select Product"
                    classNames={{
                      listbox: ["mb-0 p-0"],
                    }}
                    isRequired
                  >
                    <SelectItem className="text-white" key={"Plot Works"}>
                      {"Plot Works"}
                    </SelectItem>
                    <SelectItem className="text-white" key={"Plot Potential"}>
                      {"Plot Potential Reports"}
                    </SelectItem>
                    <SelectItem className="text-white" key={"Both"}>
                      {"Both Plot Potential & Plot Works"}
                    </SelectItem>
                  </Select>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onPress={onClose}>
                    Close
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    className="bg-[#00ff99] text-black"
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={InvestmentModal.isOpen}
        placement="center"
        scrollBehavior="outside"
        className="bg-[#333]"
        size="3xl"
        backdrop="blur"
        isDismissable={false}
        onOpenChange={closeInvestmentModal}
        style={{ fontFamily: "Verdana, sans-serif" }}
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1 text-center text-white">
            <h3 className="text-center pt-3 text-[#00ff99] font-semibold">
              Plot Potential Investment
            </h3>
            Complete the form and we’ll get back to you soon.
          </ModalHeader>
          <Form onSubmit={onSubmitForm} validationBehavior="native">
            <div className="hidden">
              <Input readOnly value={"investment"} name="form_type" />
            </div>
            <ModalBody className=" justify-center w-full  text-white">
              <div className="flex flex-row space-x-4">
                <Input
                  label="Full Name"
                  labelPlacement="outside"
                  placeholder="Enter your name"
                  isRequired
                  name="full_name"
                />
                <Input
                  label="Phone"
                  labelPlacement="outside"
                  placeholder="Enter your Phone"
                  isRequired
                  name="phone"
                />
              </div>

              <Input
                label="Email"
                type="email"
                labelPlacement="outside"
                placeholder="Enter your email"
                isRequired
                name="email"
              />
              <Select
                name="user_type"
                labelPlacement="outside"
                label="What best describes your profession?"
                placeholder="Select"
                classNames={{
                  listbox: ["mb-0 p-0"],
                }}
                isRequired
              >
                <SelectItem className="text-white" key={"Real Estate"}>
                  {"Real estate professional (e.g. agent, broker)"}
                </SelectItem>
                <SelectItem className="text-white" key={"Developer"}>
                  {"Developer (e.g. land developer, property investor)"}
                </SelectItem>
                <SelectItem className="text-white" key={"Homebuyer"}>
                  {
                    "Homebuyer or property owner exploring development opportunities"
                  }
                </SelectItem>
                <SelectItem className="text-white" key={"Media"}>
                  {"Media or press (e.g. journalist, blogger)"}
                </SelectItem>
                <SelectItem className="text-white" key={"Builder"}>
                  {"Builder / Building Companies"}
                </SelectItem>
                <SelectItem className="text-white" key={"Consultant"}>
                  {
                    "Consultant (Architect, Engineer, Planner, Traffic Consultant)"
                  }
                </SelectItem>
              </Select>


              <div className="flex flex-row py-3 ">
                <Select
                  name="contact_method"
                  labelPlacement="outside"
                  label="Would you like a Plot Potential representative to contact you?"
                  placeholder="Select"
                  classNames={{
                    listbox: ["mb-0 p-0"],
                  }}
                  isRequired
                >
                  <SelectItem className="text-white" key={"Call"}>
                    {"Yes, I'd like a follow up call"}
                  </SelectItem>
                  <SelectItem className="text-white" key={"Email"}>
                    {"Yes, email me more details"}
                  </SelectItem>
                  <SelectItem className="text-white" key={"No"}>
                    {"No, not at this time"}
                  </SelectItem>
                </Select>
              </div>

              <Textarea
                label="Any final thoughts or suggestions?"
                labelPlacement="outside"
                placeholder="Enter your thoughts / suggestions"
                name="thoughts"
              />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onPress={InvestmentModal.onClose}>
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                className="bg-[#00ff99] text-black"
              >
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
      
            <PaywallAlert />
    </>
  );
};
export default MainLandingSection;
