import React from "react";
import { fetchPost } from "../utils/helpers";
import { SubscriptionPlanId, Urls } from "../utils/AppConfig";
import { connect, useDispatch } from "react-redux";
import { toggleAlertDialog, toggleLoader } from "../actions";
import { useNavigate } from "react-router-dom";


const mapStateToProps = (state) => {
  return {
      dialog: state.DialogReducer,
      data: state.DataReducer,
      map: state.MapReducer
  }
}


const CheckIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 shrink-0"
        viewBox="0 0 20 20"
        fill="#00ff99"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    </>
  );
};

const FeatureItem = ({text}) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-1.5">
        <div className="flex items-center justify-center gap-1.5"><CheckIcon />
        <span className="text-[#00ff99]">{text}</span></div>
        <hr className="w-3/5 bg-[#00ff99] h-1" />
      </div>

    </>
  );
};

const IndependentPlan = () => {
  return (
    <>
      <div className="flex flex-col overflow-hidden rounded-3xl  border-2 border-[#CD7F32] sm:mt-8">
        <div className="flex flex-1 flex-col p-6 pt-8">
          <div className="mb-12">
            <div className="mb-2  text-2xl font-bold text-[#00ff99]">
              Independent
            </div>

            <div className="flex text-[#00ff99] justify-center  pt-4">
              <sup className="text-2xl font-extrabold ">NZ$</sup>
              <p className=" text-6xl font-extrabold ">49</p>
              <sup className="text-lg head_font2 font-extrabold">
                One <br /> Time
              </sup>
            </div>

            <div className="pt-2 pb-4 justify-center flex">
              <button className="block rounded-full bg-[#CD7F32] px-16 py-3 text-center text-sm font-semibold text-[#000]  md:text-xl"
                onClick={()=>{
                  // navigate("/map")
                }}
              >
                Coming Soon
              </button>
            </div>
            <div className="space-y-4">
              <FeatureItem text="1 Plot Potential Report" />
              <FeatureItem text="Unlimited GIS searches" />
              <FeatureItem text="Limited Building Data" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DiversePlanBeta = ({user, subscription}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subscribeToPlan = (planId) => {
    if(subscription){
      return;
    }
    dispatch(toggleLoader(true, "Initiating transaction..."));
    let url = Urls.InitiateSubscription;
    fetchPost(url, { planId })
      .then(response => {
        dispatch(toggleLoader(false));
        if(response.error){
          dispatch(toggleAlertDialog(true, "Error", "Transaction failed. Please, try again."));
          return;
        }
        window.location.href = response.paymentUrl;
        return;
      }).catch(err => {
        dispatch(toggleLoader(false));
        console.log(err);
      });
  }

  return (
    <>
      <div className="flex flex-col overflow-hidden rounded-3xl border-2 border-[#C0C0C0] lg:mt-8">
        <div className="flex flex-1 flex-col p-6 pt-8">
          <div className="mb-12">
            <div className="mb-2  text-2xl font-bold text-[#00ff99]">
            Early Adopter License Trial
            </div>

            <div className="flex text-[#00ff99] justify-center pt-4">
              <sup className="text-2xl font-extrabold ">NZ$</sup>
              <p className=" text-6xl font-extrabold ">99</p>
              <sup className="text-lg head_font2 font-extrabold">
                Per
                <br /> Month
              </sup>
            </div>

            <div className="pt-2 pb-4 justify-center flex">
              <button
                onClick={()=>{
                  if(user){
                    subscribeToPlan(SubscriptionPlanId)
                  }else{
                    navigate("/signIn")
                  }
                }}
                className="block rounded-full bg-[#C0C0C0] px-16 py-3 text-center text-sm font-semibold text-[#000]  md:text-xl"
              >
                Subscribe
              </button>
            </div>
            <div className="text-[#00ff99] px-8 mt-4">
                Early Adopter License Trial - Enjoy full access with zero commitments, provide feedback for improvements and enjoy weekly feature updates. Cancel anytime
            </div>
            <div className="space-y-4 mt-10">
              <FeatureItem text="Access to PlotX Design Tool" />
              <FeatureItem text="Access to Plot Works" />
              <FeatureItem text="Unlimited Reports" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SubscriptionSection = (props) => {

  return (
    <>
      <div
        className="pb-10 bg-black text-justify scroll-mt-[72px]"
        style={{ fontFamily: "Verdana, sans-serif" }}
      >
        <div className="bg-black py-6 sm:py-8 lg:py-12">
          <div className="mx-auto max-w-screen-xl px-4 md:px-8">
            <h2 className="mb-4 text-center text-2xl font-bold text-[#00ff99] md:mb-8 lg:text-5xl xl:mb-12 head_font2">
              Choose Your Monthly Plan
            </h2>
            <p className="mx-auto max-w-screen-md text-center text-[#00ff99] font-extrabold md:text-xl pb-16 head_font2 ">
              "See features in Independent, Diverse or Limitless."
            </p>

            <div className="mb-6 grid gap-6 lg:grid-cols-2 md:mb-8 lg:gap-12">
              {/* Independent */}
              <IndependentPlan />

              {/* Limitless */}

              {/* <LimitlessPlan /> */}

              {/* Diverse */}

              <DiversePlanBeta subscription={props.data.currentSubscription} user={props.data.user} />
            </div>
          </div>
        </div>

        {/* --------- Details & Features on individual plans --------- */}

        <div className="text-xl">
          <h2 className="py-6 text-3xl md:text-4xl text-center font-extrabold text-[#00ff99] ">
            Independent
          </h2>
          <div className="text-[#00ff99] text-center text-md md:text-xl pb-4">
            Price: NZ$49 (one-time payment for a one-time report)
          </div>
          <div className="justify-center items-center flex flex-col ">
            <ul className=" space-y-3 text-[#fff] w-11/12 md:w-3/4">
              <li>
                &#8226; Automatic Reports: Comprehensive insights including
                architecture, engineering, planning, and costing information.
              </li>
              <li>
                &#8226; Unlimited GIS Searches: Access detailed property layers.
              </li>
              <li>
                &#8226; Building Data: DVR information and comparative sales
                analysis.
              </li>
              <li className="text-md font-semibold text-[#00ff99] pt-3">
                Ideal for: First-time property buyers, individual investors, and
                anyone seeking a detailed single-property report.
              </li>
            </ul>
          </div>
        </div>

        <div className="flex justify-center items-center ">
          <hr className="w-4/5 bg-[#00ff99] h-1" />
        </div>

        <div className="text-xl">
          <h2 className="py-6 text-3xl md:text-4xl text-center font-extrabold text-[#00ff99] ">
          Early Adopter License Trial
          </h2>
          <div className="text-[#00ff99] text-center text-md md:text-xl pb-4">
            Price: NZ$99/month
          </div>
          <div className="justify-center items-center flex flex-col ">
            <ul className="space-y-3 text-[#fff] w-11/12 md:w-3/4">
              <li>
                &#8226; Automatic & Manual Reports: Unlimited reports,
                offering architecture, engineering, and planning insights.
              </li>
              <li>
                &#8226; Enhanced Remote Sensing Features: Includes advanced
                property analytics.
              </li>
              <li>
                &#8226; Architectural and Engineering Representations: Detailed
                and visual data for projects.
              </li>
              <li>
                &#8226; Access to Plot Works: Collaborate with clients on land
                development projects through Plot Works. Use integrated tools like
                chat, file sharing, and task management to work seamlessly with
                clients and contractors.
              </li>
              <li>
                &#8226; Access to Plottie University: Explore learning guides,
                blogs, and insights into current issues in the development market.
              </li>
              <li className="text-md font-semibold text-[#00ff99] pt-3">
                Ideal for: Architects, independent real estate professionals,
                builders, and investors.
              </li>
            </ul>
          </div>
        </div>

        <div className="flex justify-center items-center ">
          <hr className="w-4/5 bg-[#00ff99] h-1" />
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(SubscriptionSection);
