import { useDispatch } from "react-redux";
import { setUser, toggleLoader } from "../actions";
import HomeHeader from "../components/HomeHeader";
import ResetPassword from "../components/ResetPassword";
import "../styles/signin.css";
import BgVidImg from "../images/bgvidss.png";
import Cursor from "../components/Cursor/Cursor";
import ForgotPassword from "../components/ForgotPassword";

const ForgotPasswordPage = () => {
  return (
    <>
      <Cursor />

      <HomeHeader />
      <header
        className="relative  flex items-center justify-center h-[calc(100vh-72px)] overflow-hidden"
        style={{ fontFamily: "Verdana, sans-serif" }}
      >
        <div className=" -z-0 top-0 left-0  object-cover absolute h-screen lg:h-[calc(100vh-72px)] w-screen">
          <img
            alt="Bg"
            src={BgVidImg}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="z-10 lg:py-0 md:py-3 py-5 sm:py-2 flex items-center justify-center absolute h-[calc(100vh-72px)] w-screen content-card-video">
          <div className="mt-10"><ForgotPassword /></div>
        </div>
      </header>
    </>
  );
};

export default ForgotPasswordPage;
