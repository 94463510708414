import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import LogoImg from '../../images/logo.png';

import LogoImg from '../../images/PPLogo.png';

import UserShieldImg from '../../images/user-shield.png';
import { Theme, Urls } from '../../utils/AppConfig';
import BgVidImg from '../../images/bgvidss.png';
import { validateToken } from '../../utils/helpers';
import { useDispatch } from "react-redux";
import { setToken, setUser, toggleLoader } from '../../actions';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { EyeFilledIcon } from '../../icons/GeneralIcons';


const SignInForm2 = () => {

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const checkSignIn = (r) => {
        window.localStorage.setItem('token', r.data);
        dispatch(setToken(r.data));
    }

    const signInUser = (e) => {
        e.preventDefault();
        dispatch(toggleLoader(true));
        fetch(Urls.ClientSignIn, {
            method: 'POST',
            body: JSON.stringify({
                email, password: window.btoa(password)
            }),
            headers: {
                "Content-Type": "application/json",
            }
        }).then(r => r.json())
            .then(r => {
                dispatch(toggleLoader(false));
                console.log(r);
                if(r.error){
                    if(r.message==="WRONG_PASSWORD"){
                        return window.alert("Invalid credentials.")
                    }else{
                        return window.alert("Server error. Please, try again later")
                    }
                }
                checkSignIn(r)
            }).catch(err => {
                dispatch(toggleLoader(false));
                console.log(err);
                window.alert("Server error. Please, try again later.");
            })
    }

    const validateFields = () => {
        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
        let flag = true;
        if (!Boolean(email) || email.length === 0 || email.length > 100 || !validateEmail(email)) {
            flag = false;
        }
        if (!Boolean(password) || password.length === 0 || password.length > 100) {
            flag = false;
        }
        setFormValid(flag);

    }

    useEffect(() => { validateFields(); }, [
        password, email
    ])


    return <>
        <header className="relative  flex items-center justify-center h-[calc(100vh-72px)] overflow-hidden" style={{fontFamily: 'Verdana, sans-serif'}}>
        <div className=" -z-0 top-0 left-0  object-cover absolute h-screen lg:h-[calc(100vh-72px)] w-screen">
        <img src={BgVidImg} style={{height: "100%", width: "100%" , objectFit: 'cover'}} />
    </div>
                <div className='z-10 lg:py-0 md:py-3 py-5 sm:py-2 flex md:items-center md:justify-center absolute h-[calc(100vh-72px)] w-screen content-card-video'>
                <Container >
                <Row>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        <Row >
                            <Col lg={6} style={{ padding: 0 }}>
                                <div className='sign-in-logo-container'>
                                    <img src={LogoImg} />
                                </div>
                            </Col>
                            <Col lg={6} style={{ padding: 0 }}>
                                <div style={{ textAlign: 'center' }}>
                                    <div className='sign-in-user-img-container'>
                                        <img width="75" src={UserShieldImg} />
                                    </div>
                                </div>
                                <div className='sign-in-panel'>
                                    <div style={{ padding: '75px 32px 0 32px' }}>
                                        <h2 className='sign-in-header'>Sign In</h2>
                                    </div>
                                    <div>
                                        <form onSubmit={signInUser}>
                                            <label className='sign-in-label'>Email Id</label>
                                            <br />
                                            <input className="form-control pp-input" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            <br />
                                            <label className='sign-in-label'>Password</label>
                                            <br />
                                            <div className="flex items-center">
                                                <input
                                                    className="form-control pp-input"
                                                    name="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    type={showPassword ? "text" : "password"}
                                                />
                                                <div className='text-[#00ff99] px-2 cursor-pointer' onClick={() => setShowPassword((prev) => !prev)}><EyeFilledIcon height={30} /></div>
                                            </div>
                                            <br />
                                            <div style={{ textAlign: 'center' }}>
                                                <input disabled={!formValid} className="btn" type="submit" onSubmit={signInUser} />
                                            </div>
                                        </form>
                                        {/* <hr style={{ color: Theme.PrimaryLogo }} />
                                        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
                                    </div>
                                    <div className='flex items-center justify-end pt-2'>
                                    <div className='text-white text-sm underline'><Link to={'/forgotPassword'}>Forgot Password</Link></div>
                                    </div>
                                    <div className='flex items-center justify-center pt-2'>
                                    <div className='text-white text-sm'>Don't have an Account ? <Link style={{ color: Theme.PrimaryLogo }} to={'/signUp'}><span className='text-[#00ff99] text-lg'>SignUp</span></Link></div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </Container>                  
                </div>
            
        </header>
    </>
}





export default SignInForm2;