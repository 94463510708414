import { useState } from "react";
import * as moment from 'moment';
import { fetchPost } from "../../../utils/helpers";
import { Button } from "../../../shadcn/components/ui/button"
import { Urls } from "../../../utils/AppConfig";

const ActivePlan = ({ subscriptionData }) => {


  const cancelSubscription = (subscriptionId) => {
    let url = Urls.CancelSubscription;
    fetchPost(url, { subscriptionId })
      .then(response => {
        console.log(response);
        if (response.error) {
          return window.alert("Error");
        } else {
          window.location.href = "/home";
        }
      })
      .catch(err => console.log(err));
  }


  const BenefitsCard = ({ text }) => {

    const planBenefits = text;


    return (
      planBenefits.map((item, idx) =>
        <div key={idx} className="p-2 sm:w-1/2 w-full">
          <div className="bg-[#111111] rounded flex p-4 h-full items-center justify-center border-2 border-[#00ff99]">
          ✅ &nbsp; <span className="title-font text-lg text-white font-large">{item}</span>
          </div>
        </div>
      )
    )
  }


  return (

    <div className="items-center justify-center bg-transparent text-white text-center">
      <div className="p-6 px-0">
        <div className="text-center mb-10">
          <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-[#00ff99]">Subscription</h1>
        </div>

        <div className="flex flex-wrap justify-center items-center">
          <div className="flex flex-col  rounded p-3 w-full md:w-2/3  mb-5 bg-[#111111] border-[#00ff99] border-2">
           


            <div className="flex h-full items-center justify-between gap-3 flex-col lg:flex-row">


              <div className="text-2xl bg-[#333] p-3 border-none rounded-xl">NZ$ {parseFloat(subscriptionData.subscriptionPlan["price"]).toFixed(2)}</div>

              <div className="text-gray-900 bg-gradient-to-r from-[#00ff99] via-[#00ff99] to-[#00ff99]  shadow-lg shadow-[#00ff99]/50 font-bold rounded-lg text-xl px-5 py-3 text-center">
                {subscriptionData.subscriptionPlan['productDescription']}
              </div>
              {
                subscriptionData.subscription['status'] === 'CANCELLED' ? 'Cancelled' : 
                <Button size="lg" variant="destructive" className="text-white font-bold text-xl pt-7 pb-7" onClick={() => {
                  cancelSubscription(subscriptionData.subscription['subscription_ref']);
                }}>
                  Cancel
                </Button>
              }
            </div>



          </div>
        </div>


        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <div className="flex flex-col items-center justify-center rounded-lg bg-[#111111] p-4 lg:p-8 border-2 border-[#00ff99]">
              <div className="text-sm font-semibold sm:text-base">Subscribed On</div>
              <div className="text-xl font-bold text-[#00ff99] sm:text-2xl md:text-1xl">
                {moment(subscriptionData.subscription['subscribed_on']).format('MMM Do, YYYY')}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center rounded-lg bg-[#111111] p-4 md:p-8 border-2 border-[#00ff99]">
              <div className="text-sm font-semibold sm:text-base">Days Remaining</div>
              <div className="text-xl font-bold text-[#00ff99]  sm:text-2xl md:text-3xl">
                {Math.ceil((new Date(subscriptionData.subscription['expires_on']).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center rounded-lg bg-[#111111] p-4 md:p-8 border-2 border-[#00ff99]">
              <div className="text-sm font-semibold sm:text-base">{subscriptionData.subscription['status'] === 'CANCELLED' ? 'Expires On' : 'Renewal On'}</div>
              <div className="text-xl font-bold text-[#00ff99]  sm:text-2xl md:text-1xl">
                {moment(subscriptionData.subscription['expires_on']).format('MMM Do, YYYY')}
              </div>
            </div>
          </div>
        </div>

        <br />





        <div className="flex items-center justify-center p-3">
          <hr className="w-1/2" />
        </div>
        <section className="text-gray-600 body-font">
          <div className="container px-3 md:px-5 mx-auto">
            <div className="text-center mb-10">
              <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-[#00ff99]">Active Plan Benefits</h1>
            </div>
            <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
              <BenefitsCard text={subscriptionData.subscriptionPlan['features']} />
            </div>
          </div>
        </section>
      </div>
    </div>

  )
}

export default ActivePlan;