
import * as React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch } from "react-redux";
import { togglePaywallDialog } from "../../actions";

const OverviewListItem = ({ data, subscription }) => {
    const dispatch = useDispatch();
    return <Row container>
        <Col item xs={6}>
            <p className="subtitle2">{data.key}</p>
        </Col>
        <Col item xs={6}>
            {
                (data.key==='Ownership' && !subscription)?(
                    <p className={'caption cursor-pointer text-[#ff0000]'} onClick={()=>{
                        dispatch(togglePaywallDialog(true, false, true));
                    }}><b>Unlock &#128274;</b></p>
                ):(
                    <p className={'caption'}>{data.value}</p>
                )
            }
        </Col>
    </Row>
}

const OverviewDetails = (props) => {


    if (!props.data) {
        return '';
    }
    let data = props.data;

    return <div style={{
        padding: 15,
        color: '#fff'
    }}>
        <p style={{ textAlign: 'center' }} >
            Overview
        </p>
        <div>
            {
                Object.keys(data).map(k => {
                    return <OverviewListItem subscription={props.subscription} key={k} data={{ key: k, value: data[k] }} />
                })
            }
        </div>
    </div>
}

export default OverviewDetails;