import React, { useEffect, useState } from "react";
import { fetchGet } from "../utils/helpers";
import { Urls } from "../utils/AppConfig";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadNotifications = async () => {
      let result = await fetchGet(Urls.GetNotifications);
      if (!result.error) {
        setNotifications(result.data);
      }
    };
  
    loadNotifications();
  
    const interval = setInterval(loadNotifications, 5000);
  
    return () => clearInterval(interval);
  }, []);


  return (
    <>
      <div className="container">
        {notifications.map((notification) => {
          return (
            <div
              className="flex justify-between pt-2 pb-2 pl-4 pr-8 m-2 border-2 rounded-md hover:bg-[#333] cursor-pointer"
              key={notification.id}
              style={{ border: "1px solid #00ff99" }}
              onClick={() => {
                // navigate(`/plan?id=${plan['report_id']}`)&#9888;
              }}
            >
              <div className="flex flex-col items-start">
                <div>
                  <span className="text-[20px] text-[#00ff99]">
                    {notification["notification_message"]}
                    <span className="ml-4">
                    {
                        notification['status']==="COMPLETED_SUCCESS"?<span className="text-[#00ff99]">
                        &#10004;
                        </span>:notification['status']==="COMPLETED_FAILED"?<span className="text-[#ff1111]">
                        &#9888;
                        </span>:<span className="text-[#0022ff]">
                        &#9202;
                        </span>
                    }
                    </span>
                  </span>
                </div>
                <div>
                  <i>
                    <span className="text-[#ddd] text-[12px]">
                      {new Date(notification["created_on"]).toLocaleString()}
                    </span>
                  </i>
                </div>
              </div>
              <div className="items-start text-[20px]">
                {notification["notification_type"] === "PLAN" &&
                (notification["status"] === "COMPLETED" ||
                  notification["status"] === "COMPLETED_SUCCESS") ? (
                  <button
                    className="pp"
                    onClick={() => {
                      navigate(notification["notification_link"]);
                    }}
                  >
                    View Plan
                  </button>
                ) : (
                  ""
                )}
                {notification["notification_type"] === "REPORT" &&
                notification["status"] === "COMPLETED_SUCCESS" ? (
                  <button
                    className="pp"
                    onClick={() => {
                      navigate(notification["notification_link"]);
                    }}
                  >
                    View Report
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Notifications;
