
import { useState } from "react";
import { cn } from "../../lib/utils";
import { Marquee } from "../magicui/marquee";

import {Image, Button, Modal,  ModalContent,  ModalHeader,  ModalBody,  ModalFooter,  useDisclosure} from "@nextui-org/react";

const ReviewCard = ({id,title,img}) => {

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [imageData, setImageData] = useState(null);
  const [imageTitle, setImageTitle] = useState(null);

  const handleOpen = (title,img) => {
    setImageData(img);
    setImageTitle(title);
    onOpen();
  };


  return (

<>
<Modal isOpen={isOpen} placement="center" scrollBehavior="outside" size={'5xl'} onClose={onClose} backdrop="blur">
        <ModalContent>
              <ModalHeader className="flex flex-col gap-1 justify-center items-center text-white font-bold md:text-2xl text-lg ">{imageTitle}</ModalHeader>
              <ModalBody>
              <img src={imageData}  alt={imageTitle} className="rounded-lg border-2 border-[#00ff99]" />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
        </ModalContent>
      </Modal>


    <figure
      className={cn(
        "  md:w-[600px] w-[400px] cursor-pointer overflow-hidden rounded-xl border ",
        // light styles
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
        // dark styles
        "border-gray-50/[.1] bg-gray-50/[.10] hover:bg-gray-50/[.15]",
      )}
    >
   <div className="w-full h-full py-6 flex flex-col" >
    <div className="flex items-center justify-center pb-2">
    <img
    onClick={()=>handleOpen(title,img)}
    className="md:w-[550px] w-[350px] object-fill border-2  h-[250px] border-[#00ff99] rounded-lg" alt="" src={img}  />
    </div>
    <div className="pt-2 flex flex-row items-center justify-center text-white font-normal">
    {title}
     </div>
    </div>
    </figure>
    </>
  );
};

export function MarqueeCard({images}) {

  return (
    <div className="relative flex w-full flex-col items-center justify-center overflow-hidden">
      <Marquee reverse pauseOnHover className="[--duration:8s]">
        {images.map((image) => (
          <ReviewCard key={image.id} {...image} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/4 bg-gradient-to-r from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/4 bg-gradient-to-l from-background"></div>
    </div>
  );
}


export function MarqueeCardVertical({images}) {

  return (
    <div className="relative flex w-full flex-col items-center justify-center overflow-hidden">
      <Marquee reverse pauseOnHover className="[--duration:8s]">
        {images.map((image) => (
          <ReviewCard2 key={image.id} {...image} />
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/4 bg-gradient-to-r from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/4 bg-gradient-to-l from-background"></div>
    </div>
  );
}

const ReviewCard2 = ({id,title,img}) => {

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [imageData, setImageData] = useState(null);
  const [imageTitle, setImageTitle] = useState(null);

  const handleOpen = (title,img) => {
    setImageData(img);
    setImageTitle(title);
    onOpen();
  };


  return (

<>
<Modal isOpen={isOpen} placement="center" scrollBehavior="outside" size={'5xl'} onClose={onClose} backdrop="blur">
        <ModalContent>
              <ModalHeader className="flex flex-col gap-1 justify-center items-center text-white font-bold md:text-2xl text-lg ">{imageTitle}</ModalHeader>
              <ModalBody>
              <img src={imageData}  alt={imageTitle} className="rounded-lg border-2 border-[#00ff99]" />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
        </ModalContent>
      </Modal>


    <figure
      className={cn(
        "  md:w-[600px] w-[400px] cursor-pointer overflow-hidden rounded-xl border ",
        // light styles
        "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
        // dark styles
        "border-gray-50/[.1] bg-gray-50/[.10] hover:bg-gray-50/[.15]",
      )}
    >
   <div className="w-full h-full py-6 flex flex-col" >
    <div className="flex items-center justify-center pb-2">
    <img
    onClick={()=>handleOpen(title,img)}
    className=" object-contain border-2  h-[250px] border-[#00ff99] rounded-lg" alt="" src={img}  />
    </div>
    <div className="pt-2 flex flex-row items-center justify-center text-white font-normal">
    {title}
     </div>
    </div>
    </figure>
    </>
  );
};