/* eslint-disable react-hooks/exhaustive-deps */
import { connect, useDispatch } from "react-redux"
import { toggleAlertDialog, toggleReportLoader, toggleManualOrderDialog, toggleSamplePlansDialog, toggleLoader, toggleProgressLoader, togglePaywallDialog } from "../actions"
import { Constants, SubscriptionPlanId, Theme, Urls } from "../utils/AppConfig"
import { useEffect, useState } from "react"
import { fetchGet, fetchPost, toSlug } from "../utils/helpers";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import AddressInput from "./AddressInput"
import { useNavigate } from "react-router-dom";
import SubscriptionSection from "./SubscriptionSection";

const mapStateToProps = (state) => {
    return {
        dialog: state.DialogReducer,
        data: state.DataReducer,
        map: state.MapReducer
    }
}

const style = {
    backgroundColor: '#171717',
    border: '2px solid #000',
    boxShadow: 24,
}

const IndependentPlan = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col overflow-hidden rounded-3xl  border-2 border-[#CD7F32] sm:mt-8">
        <div className="flex flex-1 flex-col p-6 pt-8">
          <div className="mb-12">
            <div className="mb-2  text-2xl font-bold text-[#00ff99]">
              Independent
            </div>

            <div className="flex text-[#00ff99] justify-center  pt-4">
              <sup className="text-2xl font-extrabold ">NZ$</sup>
              <p className=" text-6xl font-extrabold ">49</p>
              <sup className="text-lg head_font2 font-extrabold">
                One <br /> Time
              </sup>
            </div>

            <div className="pt-2 pb-4 justify-center flex">
              <button className="block rounded-full bg-[#CD7F32] px-16 py-3 text-center text-sm font-semibold text-[#000]  md:text-xl"
                onClick={()=>{
                  navigate("/map")
                }}
              >
                Coming Soon
              </button>
            </div>
            <div className="space-y-4">
                
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DiversePlanBeta = ({user, subscription}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subscribeToPlan = (planId) => {
    if(subscription){
      dispatch(togglePaywallDialog(false, false, false));
      return;
    }
    dispatch(toggleLoader(true, "Initiating transaction..."));
    let url = Urls.InitiateSubscription;
    fetchPost(url, { planId })
      .then(response => {
        dispatch(toggleLoader(false));
        if(response.error){
          dispatch(toggleAlertDialog(true, "Error", "Transaction failed. Please, try again."));
          return;
        }
        window.location.href = response.paymentUrl;
        return;
      }).catch(err => {
        dispatch(toggleLoader(false));
        console.log(err);
      });
  }

  return (
    <>
      <div className="flex flex-col overflow-hidden rounded-3xl border-2 border-[#C0C0C0] lg:mt-8">
        <div className="flex flex-1 flex-col p-6 pt-8">
          <div className="mb-12">
            <div className="mb-2  text-2xl font-bold text-[#00ff99]">
                Early Adopter License Trial
            </div>

            <div className="flex text-[#00ff99] justify-center pt-4">
              <sup className="text-2xl font-extrabold ">NZ$</sup>
              <p className=" text-6xl font-extrabold ">99</p>
              <sup className="text-lg head_font2 font-extrabold">
                Per
                <br /> Month
              </sup>
            </div>

            <div className="pt-2 pb-4 justify-center flex">
              <button
                onClick={()=>{
                  if(user){
                    subscribeToPlan(SubscriptionPlanId)
                  }else{
                    navigate("/signIn")
                  }
                }}
                className="block rounded-full bg-[#C0C0C0] px-16 py-3 text-center text-sm font-semibold text-[#000]  md:text-xl"
              >
                Subscribe
              </button>
            </div>

            <div className="space-y-4 justify-center">
                Early Adopter License Trial - Enjoy full access with zero commitments, provide feedback for improvements and enjoy weekly feature updates. Cancel anytime.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const PaywallAlert = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return <>
        <Modal
            show={props.dialog.paywallDialog && props.dialog.paywallDialog.flag}
            onHide={() => { 
                dispatch(togglePaywallDialog(false, false, false))
             }}
            centered
            size="md"
            style={{
                backgroundColor: 'transparent'
            }}
        >
            <div sx={style}>
                <div id="modal-modal-description" sx={{ mt: 2 }} style={{
                    background: 'rgba(0, 0, 0, 1)',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.6)',
                    color: '#00FF99',borderRadius: 4
                }}>
                    <div style={{ padding: '24px 24px', border: `0px solid ${Theme.PrimaryLogo}`, borderRadius: 4 }}>
                        <p className="header-2-small" style={{ textAlign: 'center' }}>
                            {props.dialog.paywallDialog.showIndependent?'Choose your plan':'Subscribe to Unlock'}
                        </p>
                        <hr style={{ color: Theme.PrimaryLogo }} />
                        <p className="content-text-details-2" style={{ fontSize: '15px !important' }}>
                            
                        </p>
                        {/* <div className="mb-6 grid gap-6 lg:grid-cols-2 md:mb-8 lg:gap-12">
                            {props.dialog.paywallDialog.showIndependent?<IndependentPlan />:''}
                            {props.dialog.paywallDialog.showDiverse?<DiversePlanBeta />:''}
                        </div> */}
                        <div className="mb-6 grid gap-6 lg:grid-cols-1 md:mb-8 lg:gap-12">
                            {props.dialog.paywallDialog.showDiverse?<DiversePlanBeta subscription={props.data.currentSubscription} user={props.data.user} />:''}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default connect(mapStateToProps)(PaywallAlert);
