import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchPost } from "../utils/helpers";
import SuccessImg from '../images/ic_success.png';
import FailureImg from '../images/ic_failure.png';
import LoadingImg from '../images/loading.gif';
import { toggleLoader } from "../actions";
import { useDispatch } from "react-redux";
import { Urls } from "../utils/AppConfig";
import Report from '../components/Report/Report'
import HomeHeader from "../components/HomeHeader";
import Notifications from "../components/Notifications";

const NotificationPage = (props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    let reportId = searchParams.get("id");

    return <>
    <HomeHeader />
        <div className="items-center justify-center bg-transparent text-white text-center">
    <div className="p-6 px-0">
        <Notifications />
</div>
</div>
    </>
}

export default NotificationPage;