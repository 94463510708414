import * as React from 'react';
import { connect } from 'react-redux';
import MainLandingSection from '../components/Landing/MainSection';
import FooterSection from '../components/Landing/FooterSection';
import {CRMSection,AboutHomeSection,ReportSection,ServiceHomeSection,FeatureSection,VideoSection} from '../components/Landing/HomeSection';
import HeaderSection from '../components/Landing/HeaderSection';
import SubscriptionPlan from '../components/Profile/subscription/SubscriptionPlan';
import Cursor from '../components/Cursor/Cursor';
import ScrollToAnchor from '../utils/ScrollToAnchor';


const mapStateToProps = (state) => {
    return {
        data: state.DataReducer,
        map: state.MapReducer
    }
}


function LandingPage(props) {    

    return <>
    <Cursor/>
    <ScrollToAnchor />

        <>
          <HeaderSection />
          <div style={{ height: 70, width: '100%', background: 'black' }}></div>
          <MainLandingSection subscription={props.data.currentSubscription} user={props.data.user} />
          <FeatureSection/>
          <VideoSection />
          <ReportSection/>
          <CRMSection/>
          <AboutHomeSection/>
          <ServiceHomeSection />
          <SubscriptionPlan />
          <FooterSection />
        </>

    </>;
}
export default connect(mapStateToProps)(LandingPage);