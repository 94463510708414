import * as React from 'react';
import EmpLogoPng from '../images/PP.png'
import { Theme } from '../utils/AppConfig';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { connect, useDispatch } from 'react-redux';
import { setCurrentSubscription, setUser } from '../actions';
import AddressSearch from './AddressSearch';
import { Link } from 'react-router-dom';

import SubscIc from '../images/subscription.png';
import PlotWIc from '../images/plotworks_logo.png';
import ReportIc from '../images/report.png';
import MapIc from '../images/map.png';
import LogOutIc from '../images/logout.png';
import BellIc from '../images/bell.png';
import PlanIc from '../images/arch_icon.png';

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer
    }
}

const HomeHeader = (props) => {
    const dispatch = useDispatch();
    return <>
    <Navbar data-bs-theme="dark" expand="lg" style={{ backgroundColor: '#000000EE', padding: 0,fontFamily: 'Verdana, sans-serif' }} className='min-h-[72px] z-10 '>
          <Container fluid>
            <Navbar.Brand  style={{ color: Theme.PrimaryLogo, margin: 'initial' }}>
                    <Link to={"/"}>
                        <img
                            alt=""
                            src={EmpLogoPng}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            style={{maxHeight: 30, objectFit: 'contain'}}
                        />
                        {' '}
                        PlotPotential
                    </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'lg'}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${'lg'}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${'lg'}`}
              placement="end"
            >
              <Offcanvas.Header closeButton closeVariant={'white'} closeLabel='x' >
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'lg'}`}>
                <Link to={"/"}>
                        <img
                            alt=""
                            src={EmpLogoPng}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />
                        {' '}
                        PlotPotential
                    </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                    <Nav className='me-auto'>
                        {
                            props.data && props.data.user?<div style={{ margin: 16 }}><AddressSearch isInput={false} height={40} /></div>:''
                        }
                        
                        
                    </Nav>
                <Nav className="justify-content-end pe-4 space-x-4" style={{ fontFamily: 'Verdana, sans-serif' }}>
                    
                        {
                            Boolean(props.data.user) ? (
                                <Link className='nav-link' to={'/notifications'}>
                                    <div className='flex space-x-2 justify-center'>
                                        <img src={BellIc} width={20} alt='Notifications' />
                                        <span style={{
                                            color: 'white',
                                            background: 'red',
                                            marginLeft: '-10px',
                                            marginTop: '5px',
                                            borderRadius: '50%',
                                            padding: '0px 4px',
                                            fontSize: '10px'
                                        }}>*</span>
                                        <span>Notifications</span>
                                    </div>
                                </Link>
                            ) : ''
                        }
                        {
                            Boolean(props.data.user) ? (
                                <Link className='nav-link' to={'/plotworks'}>
                                    <div className='flex space-x-2 justify-center'>
                                        <img src={PlotWIc} width={15} alt='Plotworks' />
                                        <span>Plotworks</span>
                                    </div>
                                </Link>
                            ) : ''
                        }
                        {
                            Boolean(props.data.user) ? (
                                <Link className='nav-link' to={'/my-subscription'}>
                                    <div className='flex space-x-2 justify-center'>
                                        <img src={SubscIc} width={25} alt='My Subscription' />
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                            ) : ''
                        }
                        {
                            Boolean(props.data.user) ? (
                                <Link className='nav-link' to={'/reports'}>
                                    <div className='flex space-x-2 justify-center'>
                                        <img src={ReportIc} width={25} alt='Reports' />
                                        <span>Reports</span>
                                    </div>
                                </Link>
                            ) : ''
                        }
                        {
                            Boolean(props.data.user) ? (
                                <Link className='nav-link' to={'/plans'}>
                                    <div className='flex space-x-2 justify-center'>
                                        <img src={PlanIc} width={25} alt='Plans' />
                                        <span>Plans</span>
                                    </div>
                                </Link>
                            ) : ''
                        }
                        {
                            Boolean(props.data.user) ? (
                                <Link className='nav-link' to={'/map'}>
                                    <div className='flex space-x-2 justify-center'>
                                        <img width={25} src={MapIc} alt='Map' />
                                        <span>Map</span>
                                    </div>
                                </Link>
                            ) : ''
                        }
                        {
                            !Boolean(props.data.user) ? (
                                <Nav>
                                    <Link className='nav-link' to={'/signIn'}>Sign In</Link>
                                    <Link className='nav-link' to={'/signUp'}>Sign Up</Link>
                                </Nav>
                            ) : (
                                <Nav>
                                    <Nav.Link>
                                    <div className='flex space-x-2 justify-center' onClick={() => {
                                            dispatch(setUser(null));
                                            dispatch(setCurrentSubscription(null));
                                            window.localStorage.removeItem('token');
                                        }} style={{cursor: 'pointer'}}>
                                        <img alt="Log out" src={LogOutIc} width={25} />
                                        <span>Log out</span>
                                    </div>
                                    </Nav.Link>
                                </Nav>
                            )
                        }
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
    </>
}

export default connect(mapStateToProps)(HomeHeader);