import HomeHeader from "../components/HomeHeader";
import Plans from "../components/Plans";

const PlanPage = () => {
  return (
    <>
      <HomeHeader />
      <div className="items-center justify-center bg-transparent text-white text-center">
        <div className="p-6 px-0">
          <Plans />
        </div>
      </div>
    </>
  );
};

export default PlanPage;
