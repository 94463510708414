import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchPost } from "../utils/helpers";
import SuccessImg from '../images/ic_success.png';
import FailureImg from '../images/ic_failure.png';
import LoadingImg from '../images/loading.gif';
import { toggleLoader } from "../actions";
import { useDispatch } from "react-redux";
import { Urls } from "../utils/AppConfig";
import Payment from "../components/Payment/Payment";
import Subscription from "../components/Payment/Subscription";


const PaymentWaitingPage = (props) => {
    const dispatch = useDispatch();
    let isSubscription = props.paymentFor === 'subscription';
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentData, setPaymentData] = useState(null);

    const loadingDiv = useRef();
    const successDiv = useRef();
    const failureDiv = useRef();

    let orderId = searchParams.get("orderId");
    if (isSubscription) {
        orderId = searchParams.get("subscriptionId");
    }

    const hideAll = () => {

        loadingDiv.current.style.display = 'none';
        successDiv.current.style.display = 'none';
        failureDiv.current.style.display = 'none';
    }

    const gotoHome = () => {
        // window.location.href = "/home"
    }
    const gotoReport = (report) => {
        // window.location.href = "/report?id=" + report['report_id'];
    }

    useEffect(() => {
        hideAll();
        loadingDiv.current.style.display = 'block';
        let url = Urls.GetOrderPaymentStatus;
        let data = { orderId }
        if (isSubscription) {
            url = Urls.GetSubscriptionPaymentStatus;
            data = { subscriptionId: orderId }
        }
        let token = window.localStorage.getItem('token');
        setTimeout(() => {
            fetchPost(url, data, token)
                .then(r => {
                    console.log(r);
                    setPaymentData(r);
                    hideAll();
                    successDiv.current.style.display = 'block';
                })
                .catch(err => {
                    console.log(err);
                })
        }, 3000);
    }, []);


    return <>


        <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'inline-block' }}>
                <div style={{ padding: 32, marginTop: 32 }}>
                    <div ref={successDiv}>
                        {
                            isSubscription ? <Subscription paymentData={paymentData} /> : <Payment paymentData={paymentData} />
                        }
                        {/* <div style={{ textAlign: 'center' }}><img src={SuccessImg} width={50} /></div>
                        <div style={{ color: 'white' }}>Subscription Successful (redirecting...)</div>
                        <svg viewBox="0 0 1024 1024" width={50} height={50} class="icon" xmlns="http://www.w3.org/2000/svg" fill="" stroke=""><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#00000" strokeWidth="61.44"><path fill="#00ff99" d="M512 64a448 448 0 110 896 448 448 0 010-896zm-55.808 536.384l-99.52-99.584a38.4 38.4 0 10-54.336 54.336l126.72 126.72a38.272 38.272 0 0054.336 0l262.4-262.464a38.4 38.4 0 10-54.272-54.336L456.192 600.384z"></path></g><g id="SVGRepo_iconCarrier"><path fill="#00ff99" d="M512 64a448 448 0 110 896 448 448 0 010-896zm-55.808 536.384l-99.52-99.584a38.4 38.4 0 10-54.336 54.336l126.72 126.72a38.272 38.272 0 0054.336 0l262.4-262.464a38.4 38.4 0 10-54.272-54.336L456.192 600.384z"></path></g></svg> */}

                    </div>
                    <div ref={failureDiv}>
                        <img src={FailureImg} width={50} />
                        <div style={{ color: 'white' }}>Payment Failed</div>
                        <br />
                        {/* <Button size="small" style={{ background: '#333', color: '#fff' }} onClick={() => { retryPayment(); }}>Retry</Button>
                        <Button size="small" style={{ background: '#333', color: '#fff' }} onClick={() => { gotoHome(); }}>Home</Button> */}
                    </div>
                    <div ref={loadingDiv}>
                        <img src={LoadingImg} width={50} />
                        <div>Please wait...</div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default PaymentWaitingPage;