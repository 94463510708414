import HomeHeader from "../components/HomeHeader";
import PlanDetails from "../components/PlanDetails";
import PaywallAlert from "../components/PaywallAlert";

const PlanDetailsPage = () => {
  return (
    <>
      <HomeHeader />
      <div className="items-center justify-center bg-transparent text-white text-center">
        <div className="p-6 px-0">
          <PlanDetails />
        </div>
      </div>
      <PaywallAlert />
    </>
  );
};

export default PlanDetailsPage;
