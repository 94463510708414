import { useState, useEffect } from "react";
import { GeneralPopUp } from "./GeneralPopup";
import { Input } from "@nextui-org/react";
import {
  MailIcon,
} from "../icons/GeneralIcons";
import { useDispatch } from "react-redux";
import { toggleAlertDialog, toggleLoader } from "../actions";
import { useNavigate } from "react-router-dom";
import { fetchPost } from "../utils/helpers";
import { Urls } from "../utils/AppConfig";


const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [isSumitted, setSumitted] = useState(false);

  const validateFields = () => {
    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };
    let flag = true;
    if (
      !Boolean(email) ||
      email.length === 0 ||
      email.length > 100 ||
      !validateEmail(email)
    ) {
      flag = false;
    }
    setFormValid(flag);
  };

  useEffect(() => {
    validateFields();
  }, [email]);

  const sendResetEmail = async () => {
    try{
      dispatch(toggleLoader(true, "Please wait"));
      let response = await fetchPost(Urls.RequestPwdRst, {email});
      dispatch(toggleLoader(false, ""));
      if(response.error){
        if(response.message==="NO_EMAIL_FOUND"){
          dispatch(toggleAlertDialog(true, "Error", "EmailId not found"));
        }else{
          dispatch(toggleAlertDialog(true, "Error", "Please, try again later."));
        }
        return;
      }
      dispatch(toggleAlertDialog(true, "Email Sent", "Password Reset Link is sent to your registered email. Please, click that link to change your password."))
      navigate("/signIn")
    }catch(err){
      console.log(err);
      dispatch(toggleAlertDialog(true, "Error", "Please, try again later."));
    }
  };

  return (
    <>
      <section class="bg-black rounded-3xl py-4">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
          
          <div class="w-full p-6 bg-[#333333] rounded-lg shadow  md:mt-0 sm:max-w-md   sm:p-8">
            {
              !isSumitted?<>
              <div className="flex p-3 justify-center">
              <svg
              fill="#00ff99"
              viewBox="0 0 52 52"
              width={70}
              height={70}
              enable-background="new 0 0 52 52"
              stroke="#00ff99"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <path d="M42,23H10c-2.2,0-4,1.8-4,4v19c0,2.2,1.8,4,4,4h32c2.2,0,4-1.8,4-4V27C46,24.8,44.2,23,42,23z M31,44.5 c-1.5,1-3.2,1.5-5,1.5c-0.6,0-1.2-0.1-1.8-0.2c-2.4-0.5-4.4-1.8-5.7-3.8l3.3-2.2c0.7,1.1,1.9,1.9,3.2,2.1c1.3,0.3,2.6,0,3.8-0.8 c2.3-1.5,2.9-4.7,1.4-6.9c-0.7-1.1-1.9-1.9-3.2-2.1c-1.3-0.3-2.6,0-3.8,0.8c-0.3,0.2-0.5,0.4-0.7,0.6L26,37h-9v-9l2.6,2.6 c0.4-0.4,0.9-0.8,1.3-1.1c2-1.3,4.4-1.8,6.8-1.4c2.4,0.5,4.4,1.8,5.7,3.8C36.2,36.1,35.1,41.7,31,44.5z"></path>
                  <path d="M10,18.1v0.4C10,18.4,10,18.3,10,18.1C10,18.1,10,18.1,10,18.1z"></path>
                  <path d="M11,19h4c0.6,0,1-0.3,1-0.9V18c0-5.7,4.9-10.4,10.7-10C32,8.4,36,13,36,18.4v-0.3c0,0.6,0.4,0.9,1,0.9h4 c0.6,0,1-0.3,1-0.9V18c0-9.1-7.6-16.4-16.8-16c-8.5,0.4-15,7.6-15.2,16.1C10.1,18.6,10.5,19,11,19z"></path>{" "}
                </g>{" "}
              </g>
            </svg></div>
              <h1 class="mb-1 text-xl leading-tight tracking-tight  md:text-2xl text-white mb-2">
              Forgot your password?
            </h1>
            <p class="font-light text-gray-300 ">
              Enter your email and we will send you a link to reset your password.
            </p>
            <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
              <div>
                
                <input
                  className="form-control pp-input"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />

              </div>

              <button
                disabled={!formValid}
                type="button"
                className={`${
                  formValid
                    ? "cursor-pointer bg-[#00ff99]"
                    : "cursor-not-allowed bg-[#00ff99]/50"
                } w-full text-black  font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                onClick={()=>{sendResetEmail();}}
              >
                Send Password Reset Link
              </button>
            </form>
            </>:<>
            <p class="font-light text-gray-300 ">
            Password Reset Link is sent to your registered email. Please, click that link to change your password.
            </p>
            <button
                type="button"
                className={`cursor-pointer bg-[#00ff99] w-full text-black  font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                onClick={()=>{
                  navigate("/signIn");
                }}
              >
                Go to Sign In
              </button>
            </>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;